import { Box, Button, Flex, IconLoading, Modal, Text } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { useModalHelpers } from "@/app/hooks/use-modal-helpers";

import { ConfirmationModalProps } from "./confirmation-modal.types";

export default function ConfirmationModal({
  closeModal,
  visible,
  onSubmit,
  modalText,
  loading = false,
  messages,
}: ConfirmationModalProps) {
  const { t } = useTranslation();

  const { onConfirm, submitting } = useModalHelpers({
    onSubmit,
    loading,
    closeModal,
    messages,
  });

  return (
    <Modal maskClosable visible={visible} hideCloseButton onCancel={closeModal} sx={{ maxHeight: "inherit" }}>
      <Box
        sx={{
          p: 4,
        }}
      >
        <Text sx={{ fontSize: 3 }}>{t(modalText)}</Text>
        <Flex
          sx={{
            justifyContent: "flex-end",
            gap: 2,
            mt: 4,
          }}
        >
          <Button
            variant="warning"
            sx={{
              width: 9,
            }}
            onClick={closeModal}
            disabled={submitting}
          >
            {t("Cancel")}
          </Button>
          <Button
            sx={{
              width: 9,
            }}
            onClick={onConfirm}
            disabled={submitting}
          >
            {t("Confirm")}
          </Button>
          {submitting && <IconLoading />}
        </Flex>
      </Box>
    </Modal>
  );
}
