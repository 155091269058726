import { LoginCallback, useOktaAuth } from "@okta/okta-react";
import { lazy, Suspense } from "react";
import { Navigate, Outlet, useLocation } from "react-router";

import { LoadingOverlayIndicator } from "../components";
import { ActionButton } from "../components/action-button/action-button";
import { UserRoles } from "../lib/role-based-access-control";
import AccountsTable from "../pages/accounts/accounts-table/accounts-table";
import CCDepositRequestsTable from "../pages/cc-deposit-requests/cc-deposit-requests-table/cc-deposit-requests-table";
import ClearingRecordsTable from "../pages/clearing-and-settlement/clearing-records-table/clearing-records-table";
import ForgotPassword from "../pages/forgot-password/forgot-password";
import Home from "../pages/home/home";
import Notifications from "../pages/notifications/notifications";
import OtcHoldingsTable from "../pages/over-the-counter/otc-holdings-table/otc-holdings-table";
import TradingHalt from "../pages/trading-halt/trading-halt";
import TradingSummary from "../pages/trading-summary/trading-summary";
import { Route } from "./navigation.types";

const ConfirmEmail = lazy(() => import("../pages/new-admin-confirmation/confirm-email"));
const ResetPassword = lazy(() => import("../pages/reset-password/reset-password"));
const CreateEditAccount = lazy(() => import("../pages/create-edit-account/create-edit-account"));
const UpdateBalance = lazy(() => import("../pages/accounts/update-balance/update-balance"));
const CCDepositForm = lazy(() => import("../pages/cc-deposit-form/cc-deposit-form"));

const AccountDetails = lazy(() => import("../pages/accounts/account-details/account-details"));
const CCDepositRequestsDetails = lazy(
  () => import("../pages/cc-deposit-requests/cc-deposit-requests-details/cc-deposit-requests-details"),
);
const ClearingRecordDetails = lazy(
  () => import("../pages/clearing-and-settlement/clearing-record-details/clearing-record-details"),
);
const CreateOTCOrder = lazy(() => import("../pages/over-the-counter/create-otc-order/create-otc-order"));

export const CustomRouteTokenCheckers = ({ Page }: { Page: React.FC<any> }) => {
  const location = useLocation();
  const token = new URLSearchParams(location.search).get("token");
  if (token) return <Page token={token} />;
  return <Navigate to="/" replace />;
};

export const CustomLoginCallbackRoute = () => {
  const { oktaAuth } = useOktaAuth();
  /** idx.canProceed() returns true only when user is actually logging in
   * Hence disabling this route if hit directly from brower url panel
   */
  if (oktaAuth.idx.canProceed()) {
    return <LoginCallback />;
  }
  return <Navigate to="/" replace />;
};

export const routes: Route[] = [
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/reset-password",
    element: (
      <Suspense fallback={<LoadingOverlayIndicator />}>
        <CustomRouteTokenCheckers Page={ResetPassword} />
      </Suspense>
    ),
  },
  {
    path: "/login/callback",
    element: <CustomLoginCallbackRoute />,
  },
  {
    path: "/confirm-email",
    element: (
      <Suspense fallback={<LoadingOverlayIndicator />}>
        <CustomRouteTokenCheckers Page={ConfirmEmail} />
      </Suspense>
    ),
  },
  {
    path: "/accounts",
    nav: {
      displayName: "Accounts",
      icon: "ListTick",
      props: {
        headerActions: <ActionButton text={"Create Account"} action={{ navigate: `create-edit/new` }} />,
      },
    },
    element: <Outlet />,
    secured: true,
    guards: {
      roles: [UserRoles.RoleSuperAdmin, UserRoles.RoleVerificationsAdmin],
    },
    children: [
      {
        path: "",
        element: <AccountsTable />,
      },

      {
        path: "create-edit/:id",
        element: (
          <Suspense fallback={<LoadingOverlayIndicator />}>
            <CreateEditAccount />
          </Suspense>
        ),
      },
      {
        path: ":id",
        element: (
          <Suspense fallback={<LoadingOverlayIndicator />}>
            <AccountDetails />
          </Suspense>
        ),
      },
      {
        path: "update-balance/:id",
        element: (
          <Suspense fallback={<LoadingOverlayIndicator />}>
            <UpdateBalance />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/deposit-requests",
    nav: {
      displayName: "Deposit Requests",
      icon: "Institute",
    },
    element: <Outlet />,
    secured: true,
    guards: {
      roles: [UserRoles.RoleVerificationsAdmin, UserRoles.RoleSuperAdmin],
    },
    children: [
      {
        path: "",
        element: <CCDepositRequestsTable />,
      },
      {
        path: ":id",
        element: (
          <Suspense fallback={<LoadingOverlayIndicator />}>
            <CCDepositRequestsDetails />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/over-the-counter",
    nav: {
      displayName: "Over The Counter (OTC)",
      icon: "CoinStack",
    },
    secured: true,
    element: <Outlet />,
    guards: {
      roles: [UserRoles.RoleVerificationsAdmin, UserRoles.RoleSuperAdmin],
    },
    children: [
      {
        path: "",
        element: <OtcHoldingsTable />,
      },
      {
        path: ":id",
        element: (
          <Suspense fallback={<LoadingOverlayIndicator />}>
            <CreateOTCOrder />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/clearing-and-settlement",
    nav: {
      displayName: "Clearing And Settlement",
      icon: "HandBag",
    },
    secured: true,
    element: <Outlet />,
    guards: {
      roles: [UserRoles.RoleVerificationsAdmin, UserRoles.RoleSuperAdmin],
    },
    children: [
      {
        path: "",
        element: <ClearingRecordsTable />,
      },
      {
        path: ":id",
        element: (
          <Suspense fallback={<LoadingOverlayIndicator />}>
            <ClearingRecordDetails />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/deposit/:id",
    secured: true,
    element: (
      <Suspense fallback={<LoadingOverlayIndicator />}>
        <CCDepositForm />
      </Suspense>
    ),
    guards: {
      roles: [UserRoles.RoleVerificationsAdmin, UserRoles.RoleSuperAdmin],
    },
  },
  {
    nav: {
      displayName: "Trading Summary",
      icon: "LineGraph",
    },
    path: "/trading-summary",
    secured: true,
    element: <TradingSummary />,
    guards: {
      roles: [UserRoles.RoleVerificationsAdmin, UserRoles.RoleSuperAdmin],
    },
  },
  {
    path: "/trading-halt",
    nav: {
      displayName: "Trading Halt",
      icon: "Hand",
    },
    secured: true,
    element: <TradingHalt />,
    guards: {
      roles: [UserRoles.RoleVerificationsAdmin, UserRoles.RoleSuperAdmin],
    },
  },
  {
    path: "/notifications",
    secured: true,
    element: <Notifications />,
    guards: {
      roles: [UserRoles.RoleVerificationsAdmin, UserRoles.RoleSuperAdmin],
    },
  },
];
