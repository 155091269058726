import { useOktaAuth } from "@okta/okta-react";
import { useMemo } from "react";

import { CurrencyCode, useTenantsQuery } from "@/app/types/generated/graphql";

export const useCurrencySymbol = () => {
  const okta = useOktaAuth();
  const tenantId = okta?.oktaAuth?.authStateManager.getAuthState()?.accessToken?.claims?.tenantUid;
  const { data: tenantInfo, loading } = useTenantsQuery();

  const [currencySymbol, currencyCode] = useMemo(() => {
    const tenant = tenantInfo?.tenants.find((tenant) => tenant.id === tenantId);
    return [
      tenant?.localisation?.currencySymbol ?? "$",
      (tenant?.localisation?.currencyCode ?? CurrencyCode.Usd) as CurrencyCode,
    ];
  }, [tenantId, tenantInfo?.tenants]);

  return {
    currencySymbol,
    currencyCode,
    loading,
  };
};
