import { LoadingOverlay, Tabs } from "@powerledger/ui-component-lib";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Box, Card, Heading } from "theme-ui";

import { NotificationsTabType, NotificationViewProps } from "./notifications.types";
import { NotificationLists } from "./notifications-list";

export const NotificationView: React.FC<NotificationViewProps> = ({
  loading,
  currentTab,
  setCurrentTab,
  filteredNotifications,
  markAsReadHandler,
  markAsReadId,
}) => {
  const { t } = useTranslation();
  return (
    <Fragment>
      <Heading sx={{ variant: "texts.traceccDefaultHeading" }}>{t("Notifications")}</Heading>
      <Box mt="4">
        <Card variant="compact" sx={{ position: "relative", ...(loading ? { minHeight: 200 } : {}) }}>
          <LoadingOverlay loading={loading} />
          <Tabs activeKey={currentTab} onChange={setCurrentTab}>
            <Tabs.TabPane tab={t(NotificationsTabType.UNREAD)} key={NotificationsTabType.UNREAD}>
              <NotificationLists
                notifications={filteredNotifications.allUnread}
                markAsRead={markAsReadHandler}
                loading={loading}
                markAsReadId={markAsReadId}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab={t(NotificationsTabType.READ)} key={NotificationsTabType.READ}>
              <NotificationLists notifications={filteredNotifications.allRead} />
            </Tabs.TabPane>
          </Tabs>
        </Card>
      </Box>
    </Fragment>
  );
};
