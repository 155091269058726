import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { Button } from "theme-ui";

import { ActionButtonProps } from "./action-button.types";

const hasNavigateOnAction = (action: ActionButtonProps["action"]): action is { navigate: string } =>
  (action as any)?.navigate;

export const ActionButton: React.FC<ActionButtonProps> = ({ text = "", action }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Button
      sx={{
        height: 53,
        width: 203,
      }}
      onClick={() => {
        hasNavigateOnAction(action) ? navigate(action.navigate) : action.onClick();
      }}
    >
      {t(text)}
    </Button>
  );
};
