import { OtcHoldingsTableView } from "./otc-holdings-table-view";
import { useOtcHoldingsTable } from "./use-otc-holdings-table";

const OtcHoldingsTable = () => {
  const logic = useOtcHoldingsTable();

  return <OtcHoldingsTableView {...logic} />;
};

export default OtcHoldingsTable;
