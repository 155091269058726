import { startCase } from "lodash";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import notifications from "@/app/container/notification-handler";
import {
  AccountsQuery,
  AccountStatus,
  MembershipType,
  useAccountsQuery,
  useSendActivationEmailMutation,
} from "@/app/types/generated/graphql";

import { membershipTypeOptions } from "../../create-edit-account/use-create-edit-account";
import { SearchKeys, SearchTerms, UseAccountsTableFn } from "./accounts-table.types";

export const accountStatusOptions = (Object.keys(AccountStatus) as Array<keyof typeof AccountStatus>).map((key) => ({
  label: startCase(key),
  value: AccountStatus[key],
}));

export const isUserActive = (account: AccountsQuery["accounts"]["0"]) =>
  account.privacyPolicyAcceptedDate && account.rulesAcceptedDate && account.termsAcceptedDate;

export const useAccountsTable: UseAccountsTableFn = () => {
  const prevSearchState = useLocation().state?.searchKeys;

  const { t } = useTranslation();

  const [searchTerms, setSearchTerms] = useState<SearchTerms>(prevSearchState || {});
  const [sendingEmailsList, setSendingEmailsList] = useState<string[]>([]);

  const [sendEmail] = useSendActivationEmailMutation();

  const {
    data: accountsQuery,
    loading: accountsLoading,
    refetch: refetchAccounts,
  } = useAccountsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      memberFilter: {
        membershipTypes: searchTerms.membershipType
          ? [searchTerms.membershipType]
          : [MembershipType["Proprietor"], MembershipType["Trader"]],
        membershipId: searchTerms.membershipId,
      },
    },
    notifyOnNetworkStatusChange: true,
    onError(error) {
      notifications.error({
        description: t(error?.message),
      });
    },
  });
  const onAccountsSearch = (value: string, key: SearchKeys) => {
    setSearchTerms((searchTerm) => ({
      ...searchTerm,
      [key]: value || undefined,
    }));
  };
  const loading = accountsLoading;

  const memberTypeOptions = [
    { label: "Membership Type", value: undefined },
    ...membershipTypeOptions.filter((type) => type.value !== MembershipType["Client"]),
  ];

  const refetch = useCallback(() => {
    refetchAccounts();
  }, [refetchAccounts]);

  const sendActivationEmail = useCallback(
    async (email: string, member?: string) => {
      try {
        setSendingEmailsList((prev) => [...prev, email]);
        notifications.info({
          description: t("Sending activation email to {{member}}", { member }),
        });
        await sendEmail({
          variables: {
            email,
          },
        });
        notifications.success({
          description: t("Activation email sent to {{member}}", { member }),
        });
      } catch (e) {
        e instanceof Error &&
          notifications.error({
            description: e.message,
          });
      } finally {
        setSendingEmailsList((prev) => prev.filter((exEmail) => exEmail !== email));
      }
    },
    [sendEmail, t],
  );

  return {
    accounts: accountsQuery?.accounts,
    loading,
    refetch,
    onAccountsSearch,
    searchTerms,
    sendActivationEmail,
    sendingEmailsList,
    memberTypeOptions,
  };
};
