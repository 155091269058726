import { Box, Flex, IconInfo, Table, Text } from "@powerledger/ui-component-lib";
import { TFunction } from "i18next";
import { FC, useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Column, Row } from "react-table";

import { SeparatorLayout } from "@/app/components";
import { TableActionLinkButton } from "@/app/components/table-action-buttons";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { currencyFormatter } from "@/app/lib/currency-formatter";
import { getTableTranslation } from "@/app/lib/get-translations-for-components";

import { OtcTableHeader } from "../otc-table-header";
import { OTCHoldingsTableProps } from "./otc-table.types";

export const getHoldingsAvailableColumn = (t: TFunction<"translation", undefined, "translation">) => ({
  Header: () => (
    <Flex
      sx={{
        flexDirection: "column",
      }}
    >
      <Flex sx={{ width: 100, alignItems: "center" }}>
        <Text>{t("Volume")}</Text>
        <IconInfo
          size={5}
          data-tip={t(
            "The available quantity for your account may differ from the current quantity because of pending transactions that have been presented against the account, but have not yet been processed. ",
          )}
        />
      </Flex>
      <Text
        sx={{
          fontSize: 0,
          fontWeight: 400,
        }}
      >
        {t("Available / Current")}
      </Text>
    </Flex>
  ),
  id: "availableVolume",
  accessor: (row: OTCHoldingsTableProps["data"]["0"]) => row.availableBalance,
  Cell: ({ row }: { row: Row<OTCHoldingsTableProps["data"]["0"]> }) => (
    <Text sx={{ color: "textDarker" }}>
      {currencyFormatter("", row.original.availableBalance, {
        precision: 0,
        noSymbol: true,
      })}
      / {currencyFormatter("", row.original.currentBalance, { precision: 0, noSymbol: true })}
    </Text>
  ),
});

export const OtcTable: FC<OTCHoldingsTableProps> = ({ data = [], loading = false, member }) => {
  const { t } = useTranslation();

  const renderExpandedComponent = useCallback(
    (data: Record<string, string | number>) => (
      <SeparatorLayout
        data={data}
        contentStyle={{ color: "text", width: "22%" }}
        descriptionStyle={{ color: "text", width: "100%", wordBreak: "break-all" }}
      />
    ),
    [],
  );

  const tableColumns: Column<OTCHoldingsTableProps["data"]["0"]>[] = useMemo(
    () => [
      {
        Header: t("Project ID"),
        disableSortBy: true,
        accessor: (row) => row.attributes.projectId,
        Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
      },
      {
        Header: t("Location"),
        accessor: (row) => row.attributes.country,
        Cell: ({ value }: { value: string }) => <TableValueWrapper title="Locations" value={value} />,
      },
      {
        Header: t("Vintage"),
        accessor: (row) => row.attributes.vintage,
        Cell: ({ value }: { value: number }) => <TableValueWrapper value={value} />,
      },
      {
        Header: t("Registry"),
        accessor: (row) => row.attributes.registryName,
        Cell: ({ value }: { value: string }) => <TableValueWrapper value={value} />,
      },
      getHoldingsAvailableColumn(t),
      {
        Header: t("Action"),
        disableSortBy: true,
        accessor: (row) => row,
        Cell: ({ value }: { value: OTCHoldingsTableProps["data"]["0"] }) => {
          return (
            <TableActionLinkButton
              sx={{ width: 150 }}
              linkProps={{
                to: value.id,
                state: { searchKeys: member },
              }}
              text={t("Create OTC order")}
            />
          );
        },
      },
    ],
    [member, t],
  );

  return (
    <Box
      sx={{
        mt: 4,
      }}
    >
      <OtcTableHeader membershipId={member.key} loading={loading} totalHoldings={data.length} />
      <Box
        sx={{
          table: {
            "& td": {
              whiteSpace: "unset",
            },
          },
        }}
      >
        <Table
          noHover
          expandable
          columns={tableColumns}
          renderExpandedComponent={renderExpandedComponent}
          dataSource={data}
          loading={loading}
          pagination={false}
          showScrollArrows
          translation={getTableTranslation(t)}
        />
      </Box>
    </Box>
  );
};
