import { Box, getColor, useTheme } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

import { useGetLocationTexts } from "@/app/hooks/use-get-locations-text/use-get-location-texts";

const AttributeTooltipView = ({ id = "" }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { getLocationTextsWithRegion } = useGetLocationTexts();

  return (
    <ReactTooltip
      id={id || "attributes"}
      backgroundColor={getColor(theme, "secondaryDark")}
      overridePosition={(position) => ({
        left: position.left < 0 ? 0 : position.left,
        top: position.top < 0 ? 0 : position.top,
      })}
      getContent={(attributes) => {
        let attribute = attributes?.split(",");
        if (id === "Locations") {
          attribute = getLocationTextsWithRegion(attribute);
        }

        return (
          <Box sx={{ maxWidth: [350, 400, 500, id === "Locations" ? 1300 : 800] }}>
            <Box
              sx={{
                color: "textDarker",
                fontWeight: "bold",
                fontFamily: "MintGroteskV08",
              }}
            >
              {t(id || "Attributes")}
            </Box>

            {attribute?.length > 10
              ? attribute.join(" | ")
              : attribute?.map((attributesString) => (
                  <Box key={attributesString} sx={{ fontSize: 0 }}>
                    {t(attributesString)}
                  </Box>
                ))}
          </Box>
        );
      }}
    />
  );
};

export const ccAttributes = [
  "Locations",
  "Additional Co-Benefits",
  "Sectoral Scope",
  "Sustainable Development Goals (SDGs)",
  "Project Type",
];
export const AttributesTooltip = () => (
  <>
    {ccAttributes.map((attr) => (
      <AttributeTooltipView id={attr} key={attr} />
    ))}
  </>
);
