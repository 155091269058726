import { Button, Flex, IconLoading } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { AccountsSelect } from "@/app/components";

import { OtcTable } from "./components";
import { OtcHoldingsTableViewProps } from "./otc-holdings-table-view.types";

export const OtcHoldingsTableView: FC<OtcHoldingsTableViewProps> = ({
  onMemberSelect,
  member,
  loading,
  data,
  fetchHoldings,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Flex
        sx={{
          gap: 3,
          mb: 3,
          flexWrap: ["wrap", "nowrap"],
          flexDirection: ["column", "row"],
          alignItems: ["flex-start", "center"],
        }}
      >
        <AccountsSelect
          sx={{
            width: ["100%", "30%"],
          }}
          disabled={loading}
          placeholder={t("Seller's Membership ID")}
          onFieldChange={onMemberSelect}
          selectedValue={member}
        />
        <Button sx={{ width: 115 }} disabled={loading || !member?.value} onClick={fetchHoldings}>
          {t("Search")}
        </Button>
        {loading && <IconLoading size={"normal"} />}
      </Flex>
      {member?.key && !!data.length && <OtcTable data={data} loading={loading} member={member} />}
    </>
  );
};
