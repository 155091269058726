import { Box, Button, Flex, IconDownload, LegacySelect, SearchSVG, Table, Text } from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { components as selectComponents } from "react-select";
import { Column, Row } from "react-table";

import { downloadCSV, Input } from "@/app/components";
import { createAccountsDataForCSV } from "@/app/components/csv-components/accounts";
import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { TableActionLinkButton } from "@/app/components/table-action-buttons";
import { TableActionDropdown } from "@/app/components/table-action-buttons/table-action-dropdown";
import { getSelectTranslation, getTableTranslation } from "@/app/lib/get-translations-for-components";
import { AccountsQuery } from "@/app/types/generated/graphql";

import { AccountsTableViewProps } from "./accounts-table.types";
import getAccountsCsvFileName from "./helpers";
import { isUserActive } from "./use-accounts-table";

export const AccountsTableView: React.FC<AccountsTableViewProps> = ({
  accounts,
  loading,
  refetch,
  onAccountsSearch,
  searchTerms,
  memberTypeOptions,
  sendingEmailsList,
  sendActivationEmail,
}) => {
  const { t } = useTranslation();

  const tableColumns: Column<AccountsQuery["accounts"][0]>[] = useMemo(
    () => [
      {
        Header: t("Membership ID"),
        accessor: (row) => row?.members?.[0]?.membershipId,
        Cell: ({ value }: { value: string }) => <Flex>{value || "-"}</Flex>,
      },
      {
        Header: t("Membership Type"),
        accessor: (row) => row?.members?.[0]?.membershipType,
        id: "membershipType",
        Cell: ({ value }: { value: string }) => (
          <Flex sx={{ color: "textDarker" }}>{t(startCase(value?.toLowerCase()) || "-")}</Flex>
        ),
      },
      {
        Header: t("Member Name"),
        accessor: (row) => row?.members?.[0]?.name,
        id: "name",
        Cell: ({ value }: { value: string }) => (
          <Flex
            sx={{
              color: "textDarker",
            }}
          >
            {value || "-"}
          </Flex>
        ),
      },
      {
        Header: t("Status"),
        id: "status",
        Cell: ({ row }: { row: Row<AccountsQuery["accounts"][0]> }) => (
          <Flex
            sx={{
              color: "textDarker",
            }}
          >
            {isUserActive(row.original) ? t("ACTIVE") : t("PENDING")}
          </Flex>
        ),
      },

      {
        Header: t("Action"),
        id: "See-Details",
        Cell: ({ row }: { row: Row<AccountsQuery["accounts"][0]> }) => (
          <TableActionDropdown
            // For last 2 rows, change the top value
            isLastRow={(accounts?.length || 0) > 3 && row.index > (accounts?.length || 0) - 3}
            actions={[
              <TableActionLinkButton
                key={row.original.id + row.index + 1}
                linkProps={{
                  to: `/accounts/${row.original.members?.[0]?.id}`,
                  state: { searchKeys: searchTerms, accountId: row.original.id },
                }}
                text={t("See Details")}
              />,
              <TableActionLinkButton
                key={row.original.id + row.index + 2}
                linkProps={{
                  to: `/accounts/create-edit/${row.original.members?.[0]?.id}`,
                  state: { searchKeys: searchTerms, accountId: row.original.id },
                }}
                text={t("Edit")}
              />,
              <TableActionLinkButton
                key={row.original.id + row.index + 3}
                linkProps={{
                  // We need all clientcodes of account so passing account id would suffice
                  to: `/deposit/${row.original.id}`,
                }}
                text={t("Deposit Carbon Credits")}
              />,
              <TableActionLinkButton
                key={row.original.id + row.index + 4}
                linkProps={{
                  to: `/accounts/update-balance/${row.original.id}`,
                }}
                text={t("Add Balance")}
              />,
              ...(!isUserActive(row.original)
                ? [
                    <Button
                      key={row.original.id + row.index + 5}
                      variant="pill.compactSecondary"
                      sx={{
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        height: 27,
                        p: "5px, 16px, 5px, 16px",
                        "&:enabled:hover": {
                          bg: "secondary",
                          opacity: 0.8,
                        },
                      }}
                      aria-label={t("resend activation email")}
                      disabled={sendingEmailsList.includes(row.original.email)}
                      onClick={async () => {
                        await sendActivationEmail(row.original.email, row.original.members?.[0].name);
                      }}
                    >
                      {t("Resend Activation Email")}
                    </Button>,
                  ]
                : []),
            ]}
          />
        ),
      },
    ],
    [t, accounts?.length, sendingEmailsList, sendActivationEmail, searchTerms],
  );
  return (
    <>
      <Flex
        sx={{
          gap: 3,
          mb: 3,
          flexWrap: ["wrap", "nowrap"],
          flexDirection: ["column", "row"],
          alignItems: ["flex-start", "center"],
        }}
      >
        <Input
          containerSx={{
            alignItems: "center",
            width: ["100%", "80%"],
          }}
          value={searchTerms.membershipId}
          placeholder={t("Search Membership ID")}
          sx={{
            pl: "40px",
          }}
          debounce
          onChange={(e) => onAccountsSearch(e.currentTarget.value, "membershipId")}
          prefix={{
            outside: false,
            component: (
              <Box
                sx={{
                  ml: 1,
                  mr: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SearchSVG width={20} />
              </Box>
            ),
          }}
        />

        <LegacySelect
          sx={{
            width: ["100%", "20%"],
          }}
          value={memberTypeOptions.find((mem) => mem.value === searchTerms.membershipType)}
          // No Client Filtering Option In the table
          options={memberTypeOptions}
          components={{
            Option: ({ children, ...props }) => (
              // show Any in the option instead of filter name
              <selectComponents.Option {...props}>
                {children?.toString() === "Membership Type" ? "Any" : children}
              </selectComponents.Option>
            ),
          }}
          translation={getSelectTranslation(t)}
          onChange={(option) => option && onAccountsSearch(option.value, "membershipType")}
        />
        <Flex
          sx={{
            justifyContent: "flex-end",
            marginLeft: ["auto", ""],
          }}
        >
          <Button
            type="button"
            disabled={loading}
            sx={{
              position: "relative",
              padding: 0,
              bottom: "6px",
              background: "transparent",
              color: "white",
              "&:enabled:hover,&:disabled, &:enabled:focus": { background: "transparent " },
            }}
            onClick={() => {
              downloadCSV(
                getAccountsCsvFileName(searchTerms),
                createAccountsDataForCSV(accounts as AccountsQuery["accounts"]),
              );
            }}
          >
            <IconDownload width={50} height={50} />
            <Text
              sx={{
                position: "absolute",
                bottom: "-5px",
              }}
            >
              {t("CSV")}
            </Text>
          </Button>
          <RefreshIconButton loading={loading} fetchData={refetch} />
        </Flex>
      </Flex>
      <Box
        sx={{
          "> div > div": {
            minHeight: "500px",
          },
          table: {
            overflow: "visible",
          },
        }}
      >
        <Table
          columns={tableColumns}
          noHover
          dataSource={accounts || []}
          pagination={false}
          loading={loading}
          showScrollArrows
          translation={getTableTranslation(t)}
        />
      </Box>
    </>
  );
};
