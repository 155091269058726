import { Button, EmailSVG, Flex, IconLoading } from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { ErrorMessage, Form } from "@/app/components/form";
import { Input } from "@/app/components/input";

export const ForgotPasswordEmailForm = ({ onSuccess }: { onSuccess: (email: string) => void }) => {
  const { t } = useTranslation();

  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        validationSchema={Yup.object().shape({
          email: Yup.string().required(t("Email is required")).email(t("Please provide a valid email address")),
        })}
        onSubmit={async ({ email }) => onSuccess(email)}
        validateOnMount
      >
        {({ handleSubmit, isSubmitting, touched, setFieldTouched, isValid, setFieldValue }) => (
          <Form onSubmit={handleSubmit} sx={{ width: "100%" }}>
            <Form.Item>
              <Input
                name="email"
                placeholder={t("Enter your email")}
                autoCapitalize="none"
                prefix={{
                  component: (
                    <Flex
                      sx={{
                        height: "18px",
                        width: "18px",
                      }}
                    >
                      <EmailSVG width="18" color="#fff" />
                    </Flex>
                  ),
                }}
                onInput={async (e) => {
                  await setFieldValue("email", e.currentTarget.value);
                  !touched.email && setFieldTouched("email", true, true);
                }}
                sx={{ backgroundColor: "#282750" }}
              />
              <ErrorMessage name="email" sx={{ mt: 1, ml: 2, color: "warning" }} />
            </Form.Item>
            <Flex sx={{ gap: 2, mt: 3, alignItems: "center" }}>
              <Button variant="pill" type="submit" sx={{ width: 100 }} disabled={isSubmitting || !isValid}>
                {t("Next")}
              </Button>
              {isSubmitting && <IconLoading sx={{ ml: 2 }} />}
            </Flex>
          </Form>
        )}
      </Formik>
    </>
  );
};
