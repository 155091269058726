import {
  Box,
  Flex,
  Input as ThemeInput,
  InputProps as InputPropsBase,
  ThemeUIStyleObject,
} from "@powerledger/ui-component-lib";
import { ChangeEvent, FC, forwardRef, ForwardRefRenderFunction, ReactNode } from "react";

import { useDebouncedInput } from "@/app/hooks";

export type InputProps = {
  prefix?: {
    outside?: boolean;
    component: FC | ReactNode | HTMLElement | string;
  };
  suffix?: {
    outside?: boolean;
    component: FC | ReactNode | HTMLElement | string;
  };
  variant?: string;
  containerSx?: ThemeUIStyleObject;
  debounce?: boolean;
} & Omit<InputPropsBase, "prefix">;

const component: ForwardRefRenderFunction<HTMLInputElement, InputProps> = (
  { suffix, debounce = false, prefix, variant = "input", name = "", onInput, placeholder, containerSx, sx, ...props },
  ref,
) => {
  const { handleFieldChange, localState, handleFieldBlur } = useDebouncedInput({
    onChange: (e: ChangeEvent<HTMLInputElement>) => {
      props.onChange?.(e);
    },
    name,
    value: props.value as string,
    onBlur: props.onBlur,
    shouldDebounce: debounce,
  });
  return (
    <Flex
      sx={{
        alignItems: "center",
        position: "relative",
        ...containerSx,
      }}
    >
      {prefix && (
        <Box
          sx={{
            color: "textDarker",
            fontWeight: "light",
            zIndex: 1,
            ...(prefix?.outside
              ? {
                  mr: 2,
                }
              : {
                  position: "absolute",
                  left: 2,
                }),
          }}
        >
          {prefix?.component}
        </Box>
      )}

      <ThemeInput
        as={ThemeInput}
        name={name}
        variant={variant}
        placeholder={placeholder}
        sx={{
          pl: prefix ? 4 : 3,
          pr: suffix ? 4 : 3,
          position: "relative",
          ...sx,
        }}
        {...props}
        ref={ref}
        {...(!debounce ? { onInput } : { onChange: handleFieldChange, value: localState, onBlur: handleFieldBlur })}
      />

      {suffix && (
        <Flex
          sx={{
            flexShrink: 0,
            fontWeight: "light",
            ...(suffix?.outside
              ? {
                  ml: 2,
                }
              : {
                  position: "absolute",
                  right: 2,
                }),
          }}
        >
          {suffix?.component}
        </Flex>
      )}
    </Flex>
  );
};

export const Input = forwardRef(component);
