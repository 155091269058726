import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";

import { MembershipType, useAccountsLazyQuery } from "../../types/generated/graphql";
import { UseAccountSelectFn } from "./accounts-select.types";

export const useAccountsSelect: UseAccountSelectFn = ({ pageSize = 10, filter, optionFilter = (data) => data }) => {
  const [getAccounts, { data: accountsQuery, loading: loadingAccountsData }] = useAccountsLazyQuery();
  const [scrollCursor, setScrollCursor] = useState(1);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    searchText.length > 2 &&
      getAccounts({
        variables: {
          memberFilter: {
            ...filter,
            membershipTypes: [MembershipType.Proprietor, MembershipType.Trader],
            membershipId: searchText,
          },
        },
      });
  }, [getAccounts, filter, searchText]);

  const baseOptions =
    accountsQuery?.accounts?.map((account) => {
      const member = account.members?.[0];
      return {
        label: `${member?.name || ""} | ${member?.membershipId || ""}`,
        value: account.id,
        key: member?.membershipId,
      };
    }) || [];

  const cachedOptions = baseOptions.slice(0, scrollCursor * pageSize);

  const getMoreData = useCallback(() => {
    setScrollCursor((cursor) => cursor + 1);
  }, []);

  const onAccountsSearch = useCallback((text = "") => {
    setSearchText(text);
    return true;
  }, []);

  const onCustomSearch = useMemo(() => debounce(onAccountsSearch, 500), [onAccountsSearch]);

  useEffect(() => {
    return () => {
      onCustomSearch.cancel();
    };
  }, [onCustomSearch]);

  return {
    cachedAccountsOptions: optionFilter(cachedOptions),
    getMoreData,
    onCustomSearch,
    loadingAccountsData,
    baseAccountsOptions: optionFilter(baseOptions),
  };
};
