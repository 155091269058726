import { startCase } from "lodash";
import { useCallback, useState } from "react";
import { useLocation } from "react-router";

import { CarbonCreditAuditStatus, useCarbonCreditsForAdminQuery } from "@/app/types/generated/graphql";

import { ccDepositFilterTerms, UseCCDepositRequestsTableFn } from "./cc-deposit-requests-table.types";

export const statusTypeOptions = [
  { label: "Status", value: undefined },
  ...Object.values(CarbonCreditAuditStatus).map((type) => ({
    label: startCase(type.toLocaleLowerCase()),
    value: type,
  })),
];

export const useCCDepositRequestsTable: UseCCDepositRequestsTableFn = () => {
  const statusState = useLocation().state?.statusState;
  const [ccDepositFilter, setCCDepositFilter] = useState<ccDepositFilterTerms>({
    statusType: statusState,
  });

  const {
    data: cCForAdminQuery,
    loading,
    refetch: refetchCCForAdmin,
  } = useCarbonCreditsForAdminQuery({
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      where: {
        status: ccDepositFilter?.statusType ? [ccDepositFilter.statusType] : undefined,
      },
    },
  });

  const refetch = useCallback(() => {
    refetchCCForAdmin();
  }, [refetchCCForAdmin]);

  return {
    cCForAdminQuery,
    loading,
    refetch,
    setCCDepositFilter,
    ccDepositFilter,
  };
};
