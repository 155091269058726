import { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";

import { ModalContext } from "@/app/components/modal-provider/modal-provider";
import notifications from "@/app/container/notification-handler";
import {
  CurrentTradingHaltDocument,
  UpcomingTradingHaltsDocument,
  useCancelTradingHaltMutation,
  useCurrentTradingHaltQuery,
  useTenantsQuery,
  useUpcomingTradingHaltsQuery,
} from "@/app/types/generated/graphql";

import { useTradingHaltHook } from "./trading-halt.types";

export const useTradingHalt: useTradingHaltHook = () => {
  const { t } = useTranslation();
  const {
    data: tradeHaltData,
    loading: tradeHaltLoading,
    error: tradeHaltError,
  } = useCurrentTradingHaltQuery({
    onError(error) {
      notifications.error({
        description: t(error?.message),
      });
    },
  });
  const {
    data: upcomingTradeHaltsData,
    loading: upComingTradeHaltsLoading,
    error: upComingTradeHaltsError,
  } = useUpcomingTradingHaltsQuery();
  const { data: tenantsData, loading: tenantsLoading } = useTenantsQuery({
    onError(error) {
      notifications.error({
        description: t(error?.message),
      });
    },
  });

  const { showEditTradingHaltModal, showConfirmDeleteTradingHaltModal } = useContext(ModalContext);

  const [cancelHalt] = useCancelTradingHaltMutation({
    refetchQueries: [CurrentTradingHaltDocument, UpcomingTradingHaltsDocument],
  });

  const cancelTradingHalt = useCallback(
    async (haltId = "") => {
      if (haltId) {
        const onConfirm = async () => {
          try {
            await cancelHalt({
              variables: {
                cancelTradingHaltId: haltId,
              },
            });
            notifications.success({
              description: t("Trading Halt Cancelled"),
            });
          } catch (e) {
            e instanceof Error &&
              notifications.error({
                description: e?.message,
              });
          }
        };
        showConfirmDeleteTradingHaltModal({
          onConfirm,
        });
      } else {
        return void 0;
      }
    },
    [cancelHalt, t, showConfirmDeleteTradingHaltModal],
  );

  return {
    cancelTradingHalt,
    showEditTradingHaltModal,
    tradeHaltData,
    tradeHaltLoading,
    tradeHaltError,
    upcomingTradeHaltsData,
    upComingTradeHaltsLoading,
    tenantsData,
    tenantsLoading,
    upComingTradeHaltsError,
  };
};
