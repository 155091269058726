import { Box, Flex, IconStop, Input as ThemeInput, InputProps as InputPropsBase } from "@powerledger/ui-component-lib";
import { Field, useField } from "formik";
import { ChangeEvent, FC, forwardRef, ReactNode, useMemo } from "react";

import { useDebouncedInput } from "@/app/hooks/use-debounced-input";

export type FormInputProps = {
  errorInputVariant?: "errorInput" | "errorInputHighlighted";
  hasErrorIndicator?: boolean;
  prefix?: FC | ReactNode | HTMLElement | string;
  suffix?: FC | ReactNode | HTMLElement | string;
  debounce?: boolean;
} & Omit<InputPropsBase, "prefix">;

export const FormInput: FC<FormInputProps> = forwardRef(function Input(
  {
    suffix,
    prefix,
    name = "",
    hasErrorIndicator = true,
    errorInputVariant = "errorInputHighlighted",
    debounce = false,
    onChange,
    ...props
  },
  ref,
) {
  const [{ onChange: onFieldChange, value, onBlur }, meta] = useField({ name });
  const isShowingError = useMemo(() => meta.error && meta.touched, [meta]);
  const { handleFieldChange, localState, handleFieldBlur } = useDebouncedInput({
    onChange: onChange ?? onFieldChange,
    name,
    value,
    onBlur,
    shouldDebounce: debounce,
  });

  return (
    <Flex
      sx={{
        alignItems: "center",
        width: "100%",
      }}
    >
      <Flex
        sx={{
          alignItems: "center",
          position: "relative",
          width: "100%",
          ...props.sx,
        }}
      >
        <Field
          as={ThemeInput}
          {...props}
          name={name}
          value={localState}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const val = e.currentTarget.value || e.target.value;
            if (props.pattern) {
              const pattern = new RegExp(props.pattern);
              if (pattern.test(val)) {
                handleFieldChange(e);
              }
            } else {
              handleFieldChange(e);
            }
          }}
          variant={isShowingError ? errorInputVariant : "input"}
          ref={ref}
          onBlur={handleFieldBlur}
          sx={{
            pl: prefix ? 4 : 3,
            pr: suffix ? 4 : 3,
            position: "relative",
            ...props.sx,
          }}
          autofillBackgroundColor="foregroundLight"
        />
        {prefix && (
          <Box
            sx={{
              position: "absolute",
              left: 2,
              color: "textDarker",
              fontWeight: "light",
            }}
          >
            {prefix}
          </Box>
        )}
        {hasErrorIndicator && isShowingError && (
          <IconStop
            color="warning"
            size={5}
            sx={{
              position: "absolute",
              right: 2,
            }}
          />
        )}
      </Flex>
      {suffix && (
        <Flex
          sx={{
            flexShrink: 0,
            ml: 2,
            color: "textDarker",
            fontWeight: "light",
          }}
        >
          {suffix}
        </Flex>
      )}
    </Flex>
  );
});
