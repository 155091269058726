import { Flex, LegacySelect, Table } from "@powerledger/ui-component-lib";
import { startCase } from "lodash";
import { FC, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { components as selectComponents } from "react-select";
import { Column, Row } from "react-table";

import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { TableActionLinkButton } from "@/app/components/table-action-buttons";
import { TableValueWrapper } from "@/app/components/table-value-wrapper";
import { getSelectTranslation, getTableTranslation } from "@/app/lib/get-translations-for-components";
import { CarbonCreditsForAdminQuery } from "@/app/types/generated/graphql";

import { CCDepositRequestsTableViewProps } from "./cc-deposit-requests-table.types";
import { statusTypeOptions } from "./use-cc-deposit-requests-table";

export const CCDepositRequestsTableView: FC<CCDepositRequestsTableViewProps> = ({
  loading,
  cCForAdminQuery,
  refetch,
  setCCDepositFilter,
  ccDepositFilter,
}) => {
  const { t } = useTranslation();

  const tableColumns: Column<CarbonCreditsForAdminQuery["carbonCreditsForAdmin"][0]>[] = useMemo(
    () => [
      {
        Header: t("Membership ID"),
        accessor: (row) => row?.carbonCredit?.account?.members?.[0]?.membershipId,
        id: "membershipID",
        Cell: ({ value }: { value: string }) => <Flex sx={{ color: "textDarker" }}>{t(value) || "-"}</Flex>,
      },
      {
        Header: t("Registry"),
        accessor: (row) => row?.carbonCredit?.registryName,
        id: "registryName",
        Cell: ({ value }: { value: string }) => <Flex sx={{ color: "textDarker" }}>{t(value) || "-"}</Flex>,
      },
      {
        Header: t("Project ID"),
        accessor: (row) => row?.carbonCredit?.projectId,
        id: "projectId",
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => <Flex sx={{ color: "textDarker" }}>{value || "-"}</Flex>,
      },
      {
        Header: t("Project Name"),
        accessor: (row) => row?.carbonCredit?.projectName,
        id: "projectName",
        Cell: ({ value }: { value: string }) => <Flex sx={{ color: "textDarker" }}>{t(value) || "-"}</Flex>,
      },
      {
        Header: t("Project Type"),
        accessor: (row) => row?.carbonCredit?.projectTypes,
        id: "projectTypes",
        disableSortBy: true,
        Cell: ({ value }: { value: string }) => (
          <TableValueWrapper
            containerSx={{ color: "textDarker" }}
            maxContent={1}
            title="Project Type"
            value={value || "-"}
          />
        ),
      },
      {
        Header: t("Quantity"),
        accessor: (row) => row?.carbonCredit?.volume,
        Cell: ({ value }: { value: number }) => (
          <Flex sx={{ color: "textDarker" }} key={value}>
            {t("{{qntCredits}}", {
              qntCredits: value?.toLocaleString() || 0,
            })}
          </Flex>
        ),
      },
      {
        Header: t("Status"),
        accessor: (row) => row?.status,
        Cell: ({ value }: { value: string }) => (
          <Flex sx={{ color: "textDarker" }}>{t(startCase(value.toLocaleLowerCase())) || "-"}</Flex>
        ),
      },
      {
        Header: t("Action"),
        id: "See-Details",
        Cell: ({ row }: { row: Row<CarbonCreditsForAdminQuery["carbonCreditsForAdmin"][0]> }) => (
          <TableActionLinkButton
            sx={{
              width: 120,
            }}
            key={row.original.id + row.index + 1}
            linkProps={{
              to: `/deposit-requests/${row.original.id}`,
            }}
            text={t("See Details")}
          />
        ),
      },
    ],
    [t],
  );

  return (
    <>
      <Flex
        sx={{
          mb: 3,
          gap: 2,
        }}
      >
        <LegacySelect
          disabled={loading}
          sx={{
            width: ["100%", "100%"],
          }}
          options={statusTypeOptions}
          value={statusTypeOptions.find((status) => status.value === ccDepositFilter?.statusType)}
          translation={getSelectTranslation(t)}
          onChange={(option) => option && setCCDepositFilter({ statusType: option.value })}
          components={{
            Option: ({ children, ...props }) => (
              // show Any in the option instead of filter name
              <selectComponents.Option {...props}>
                {children?.toString() === "Status" ? "Any" : children}
              </selectComponents.Option>
            ),
          }}
        />
        <Flex
          sx={{
            justifyContent: "flex-end",
            marginLeft: ["auto", ""],
          }}
        >
          <RefreshIconButton loading={loading} fetchData={refetch} />
        </Flex>
      </Flex>

      <Table
        columns={tableColumns}
        dataSource={cCForAdminQuery?.carbonCreditsForAdmin ?? []}
        pagination={false}
        loading={loading}
        showScrollArrows
        translation={getTableTranslation(t)}
      />
    </>
  );
};
