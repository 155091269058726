import { ApolloError } from "@apollo/client";
import { useOktaAuth } from "@okta/okta-react";
import { useState } from "react";

import { AssetAttributeOptions, formatAssetOptions } from "@/app/lib/format-asset-options";
import { useCcOrderAttributesOptionsQuery } from "@/app/types/generated/graphql";

export const useCCOptions = () => {
  const [formattedCCOptions, setFormattedCCOptions] = useState<AssetAttributeOptions>();
  const okta = useOktaAuth();
  const loggedIn = okta?.authState?.isAuthenticated;

  const tenantId = okta?.oktaAuth?.authStateManager.getAuthState()?.accessToken?.claims?.tenantUid;

  const [accumulatedErrors, setAccumulatedErrors] = useState<ApolloError | Error | undefined>();
  // TODO Skip Query when the account status is something
  const { loading: assetsLoading } = useCcOrderAttributesOptionsQuery({
    skip: !tenantId || !loggedIn,
    variables: {
      tenantId,
    },
    onCompleted: (data) => {
      try {
        const formattedOptions = formatAssetOptions(data?.assets);
        setFormattedCCOptions(formattedOptions);
      } catch (error) {
        if (error instanceof Error) {
          setAccumulatedErrors(error);
        }
      }
    },
    onError: (error) => {
      setAccumulatedErrors(error);
    },
  });

  if (accumulatedErrors) {
    throw accumulatedErrors;
  }
  return {
    ccOptions: formattedCCOptions,
    loading: assetsLoading,
    error: accumulatedErrors,
  };
};
