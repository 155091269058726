import { CCDepositRequestsCustomProps } from "../cc-deposit-requests-modal/cc-deposit-requests-modal.types";
import { ConfirmCommodityExportCustomProps } from "../confirm-commodity-export-modal/confirm-commodity-export-modal.types";
import { ConfirmDeleteTradingHaltModalCustomProps } from "../confirm-delete-trading-halt-modal/confirm-delete-trading-halt-modal.types";
import { ConfirmImportModalCustomProps } from "../confirm-import-modal/confirm-import-modal.types";
import { ConfirmVerifyAccountModalCustomProps } from "../confirm-verify-account-modal";
import { ConfirmationModalCustomProps } from "../confirmation-modals/confirmation-modal.types";
import { EditTradingHaltModalCustomProps } from "../edit-trading-halt-modal.tsx/edit-trading-halt-modal.types";

export type ModalHandler = {
  showContactSupportModal: () => void;
  showConfirmVerifyAccountModal: (args: ConfirmVerifyAccountModalCustomProps) => void;
  showEditTradingHaltModal: (args: EditTradingHaltModalCustomProps) => void;
  showConfirmDeleteTradingHaltModal: (args: ConfirmDeleteTradingHaltModalCustomProps) => void;
  showConfirmCommodityExportModal: (args: ConfirmCommodityExportCustomProps) => void;
  showCCDepositRequestsModal: (args: CCDepositRequestsCustomProps) => void;
  showConfirmImportModal: (args: ConfirmImportModalCustomProps) => void;
  showConfirmationModal: (args: ConfirmationModalCustomProps) => void;
};

export type ModalProviderProps = {
  children: React.ReactNode;
};

export enum ModalEnums {
  ContactSupportModal = "ContactSupportModal",
  ConfirmCommodityExport = "ConfirmCommodityExport",
  ConfirmVerifyAccountModal = "ConfirmVerifyAccountModal",
  IdentityMetaDataModal = "IdentityMetaDataModal",
  EditTradingHaltModal = "EditTradingHaltModal",
  ConfirmDeleteTradingHaltModal = "ConfirmDeleteTradingHaltModal",
  CCDepositRequestsModal = "CCDepositRequestsModal",
  ConfirmImport = "ConfirmImport",
  ConfirmationModal = "ConfirmationModal",
}

export type ModalState =
  | {
      type: ModalEnums.ContactSupportModal;
      customProps: undefined;
    }
  | {
      type: ModalEnums.ConfirmVerifyAccountModal;
      customProps: ConfirmVerifyAccountModalCustomProps;
    }
  | {
      type: ModalEnums.EditTradingHaltModal;
      customProps: EditTradingHaltModalCustomProps;
    }
  | {
      type: ModalEnums.ConfirmDeleteTradingHaltModal;
      customProps: ConfirmDeleteTradingHaltModalCustomProps;
    }
  | {
      type: ModalEnums.ConfirmCommodityExport;
      customProps: ConfirmCommodityExportCustomProps;
    }
  | {
      type: ModalEnums.CCDepositRequestsModal;
      customProps: CCDepositRequestsCustomProps;
    }
  | {
      type: ModalEnums.ConfirmImport;
      customProps: ConfirmImportModalCustomProps;
    }
  | { type: ModalEnums.ConfirmationModal; customProps: ConfirmationModalCustomProps };
