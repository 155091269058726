import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";

import { notifications } from "@/app/container/notification-handler";
import { useCurrencySymbol } from "@/app/hooks/use-currency-symbol";
import { ClearingRecordStatus, useClearingRecordsQuery } from "@/app/types/generated/graphql";

import {
  ClearingRecordsSearchKeys,
  ClearingRecordsSearchParams,
  UseClearingRecordsTableFn,
} from "./clearing-records-table.types";

export const useClearingRecordsTable: UseClearingRecordsTableFn = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useState<ClearingRecordsSearchParams>({
    searchText: "",
    status: null,
  });

  const { currencySymbol, loading: loadingCurrencySymbol } = useCurrencySymbol();

  const {
    data,
    loading: loadingClearingRecords,
    refetch,
  } = useClearingRecordsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      where: {
        referenceCode: searchParams.searchText,
        statusNotIn: [ClearingRecordStatus.Completed],
        ...(searchParams.status && { statusIn: [searchParams.status] }),
      },
    },
    notifyOnNetworkStatusChange: true,
    onError(error) {
      notifications.error({
        description: t(error?.message),
      });
    },
  });

  const onClearingRecordSearch = useCallback((value: string | null, key: ClearingRecordsSearchKeys) => {
    setSearchParams((searchParams) => ({
      ...searchParams,
      [key]: value,
    }));
  }, []);

  const refetchOrders = useCallback(() => {
    refetch();
  }, [refetch]);

  return {
    orders: data?.clearingRecords.clearingRecords || [],
    loading: loadingCurrencySymbol || loadingClearingRecords,
    refetchOrders,
    searchParams,
    onClearingRecordSearch,
    currencySymbol,
  };
};
