import { Box, Flex, LegacySelect, SearchSVG, Table } from "@powerledger/ui-component-lib";
import { camelCase, startCase } from "lodash";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { components as selectComponents } from "react-select";
import { Column, Row } from "react-table";

import { Input } from "@/app/components";
import { RefreshIconButton } from "@/app/components/refresh-icon-button";
import { TableActionLinkButton } from "@/app/components/table-action-buttons";
import { currencyFormatter } from "@/app/lib/currency-formatter";
import { formatDate } from "@/app/lib/format-date";
import { getSelectTranslation, getTableTranslation } from "@/app/lib/get-translations-for-components";
import { ClearingRecord, ClearingRecordStatus } from "@/app/types/generated/graphql";

import { orderOptions } from "../helpers";
import { ClearingRecordsTableViewProps } from "./clearing-records-table.types";

export const ClearingRecordsTableView: React.FC<ClearingRecordsTableViewProps> = ({
  loading,
  searchParams,
  onClearingRecordSearch,
  orders,
  refetchOrders,
  currencySymbol,
}) => {
  const { t } = useTranslation();

  const tableColumns: Column<ClearingRecordsTableViewProps["orders"][0]>[] = useMemo(
    () => [
      {
        Header: t("Order Placement Date"),
        accessor: (row) => row.createdAt,
        Cell: ({ value }: { value: string }) => (
          <Flex sx={{ color: "textDarker" }}>
            {formatDate(value, {
              noTZ: true,
            }) || "-"}
          </Flex>
        ),
      },
      {
        Header: t("Reference ID"),
        accessor: (row) => row.referenceCode,
        id: "referenceCode",
        Cell: ({ value }: { value: string }) => <Flex sx={{ color: "textDarker" }}>{value || "-"}</Flex>,
      },

      {
        Header: t("Buyer's Membership ID"),
        accessor: (row) => row.buyerMemberCode,
        id: "buyerMemberCode",
        Cell: ({ value }: { value: string }) => <Flex sx={{ color: "textDarker" }}>{value || "-"}</Flex>,
      },
      {
        Header: t("Seller's Membership ID"),
        accessor: (row) => row.sellerMemberCode,
        id: "sellerMemberCode",
        Cell: ({ value }: { value: string }) => <Flex sx={{ color: "textDarker" }}>{value || "-"}</Flex>,
      },
      {
        Header: (
          <Box
            sx={{
              width: "80px",
              textAlign: "right",
            }}
          >
            {t("Order Price")}
          </Box>
        ),
        accessor: (row) => row.tradeAmount.amount,
        id: "totalAmount",
        maxWidth: 100,
        Cell: ({ value }: { value: string }) => (
          <Box sx={{ color: "text", width: "100px", px: 2, textAlign: "right" }}>
            {currencyFormatter(currencySymbol, value)}
          </Box>
        ),
      },
      {
        Header: t("Status"),
        accessor: (row) => startCase(camelCase(row.status)),
        id: "status",
        Cell: ({ value }: { value: ClearingRecordStatus }) => <Flex sx={{ color: "textDarker" }}>{t(value)}</Flex>,
      },
      {
        Header: t("Action"),
        id: "See-Details",
        Cell: ({ row }: { row: Row<ClearingRecord> }) => (
          <TableActionLinkButton
            sx={{
              width: 120,
            }}
            key={row.original.id}
            linkProps={{
              to: `/clearing-and-settlement/${row.original.id}`,
            }}
            text={t("See Details")}
          />
        ),
      },
    ],
    [currencySymbol, t],
  );

  return (
    <>
      <Flex
        sx={{
          gap: 3,
          mb: 3,
          flexWrap: ["wrap", "nowrap"],
          flexDirection: ["column", "row"],
          alignItems: ["flex-start", "center"],
        }}
      >
        <Input
          containerSx={{
            alignItems: "center",
            width: ["100%", "75%"],
          }}
          placeholder={t("Search by Reference ID")}
          sx={{
            pl: "40px",
          }}
          debounce
          onChange={(event) => onClearingRecordSearch(event.currentTarget.value, "searchText")}
          prefix={{
            outside: false,
            component: (
              <Box
                sx={{
                  ml: 1,
                  mr: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SearchSVG width={20} />
              </Box>
            ),
          }}
        />

        <LegacySelect
          sx={{
            width: ["100%", "25%"],
          }}
          translation={getSelectTranslation(t)}
          placeholder={t("Status")}
          options={orderOptions}
          value={orderOptions.find((option) => option.value === searchParams.status) ?? null}
          onChange={(option) => onClearingRecordSearch(option?.value ?? null, "status")}
          components={{
            Option: ({ children, ...props }) => (
              // show Any in the option instead of filter name
              <selectComponents.Option {...props}>
                {children?.toString() === "Status" ? "Any" : children}
              </selectComponents.Option>
            ),
          }}
        />

        <Flex
          sx={{
            justifyContent: "flex-end",
            marginLeft: ["auto", ""],
          }}
        >
          <RefreshIconButton loading={loading} fetchData={refetchOrders} />
        </Flex>
      </Flex>
      <Table
        columns={tableColumns}
        noHover
        dataSource={orders}
        pagination={false}
        loading={loading}
        showScrollArrows
        translation={getTableTranslation(t)}
      />
    </>
  );
};
