import { useOktaAuth } from "@okta/okta-react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useCCOptions } from "../use-cc-options";

/**
 * Provide various function to provide country of given location
 */
export const useGetLocationTexts = () => {
  const auth = useOktaAuth();

  const { ccOptions } = useCCOptions();
  const { t } = useTranslation();

  const getLocationTextsWithRegion = useCallback(
    (locations: string[]) => {
      return !auth?.authState?.isAuthenticated
        ? locations
        : locations?.map((loc) => {
            let fullLocation = loc;
            const region =
              ccOptions?.country.find((opt) =>
                opt.options?.find((subOpt) => {
                  const match =
                    subOpt.label.toLowerCase() === loc.toLowerCase().trim() ||
                    subOpt.value.toLowerCase() === loc.toLowerCase().trim();
                  if (match) {
                    /** IN -> India */
                    fullLocation = subOpt.label;
                    return match;
                  }
                }),
              )?.label ?? "";
            return t("{{locationText}}", { locationText: region ? fullLocation + " (" + region + ")" : loc });
          });
    },
    [ccOptions?.country, auth, t],
  );

  return { getLocationTextsWithRegion };
};
