/** Fed in by our Kubernetes configmap */
export interface TraceXKubernetesConfigMapAppConfig {
  OKTA_CLIENT_ID: string;
  OKTA_ISSUER: string;
  OKTA_BASE_URL: string;
  APOLLO_GRAPH_VARIANT: string;
  FEDERATED_GATEWAY_URL: string;
  CONFIGCAT_SDK_KEY: string;
  CONFIGCAT_OVERRIDES: string;
  CONFIGCAT_CACHE_TTL: string;
  LOCIZE_PROJECTID: string;
  LOCIZE_APIKEY: string;
  USE_GRAPHQL_DEMO_DATA: string;
  LOCIZE_REFLNG: string;
  LOCIZE_VERSION: string;
  IS_PRODUCTION: string;
  MAPBOX_ACCESS_TOKEN: string;
  DOCUMENT_DECRYPTION_URL: string;
  GST: number;
  BUYER_FEE_PER_UNIT: number;
  SELLER_FEE_PER_UNIT: number;
  BUYER_FEE: number;
  SELLER_FEE: number;
}

declare global {
  interface Window {
    appConfig: TraceXKubernetesConfigMapAppConfig;
  }
}

const DEFAULT_JSON = "{}";

/**
 * Convert environment variables with strings 'true' or 'false' to their respective booleans
 * @param val an environment variable
 */
const parseBoolEnvVar = (val: string | undefined) => {
  if (val) {
    if (val.toLocaleLowerCase() === "true") return true;
    if (val.toLocaleLowerCase() === "false") return false;
  }
  return undefined;
};

/**
 * Convert environment variables with strings in JSON format into an object
 * If the variable is undefined (not in .env) or not valid JSON, an empty object is returned
 * @param val
 */
const parseJsonEnvVar = (val: string | undefined) => {
  if (!val) {
    val = DEFAULT_JSON;
  }

  try {
    return JSON.parse(String(val));
  } catch (e) {
    return JSON.parse(DEFAULT_JSON);
  }
};

const envVarPrefix = import.meta.env.STORYBOOK ? "STORYBOOK" : "VITE";
// Pull in the environment variable from the build config (.env file)
const buildConfig = {
  OKTA_ISSUER: import.meta.env[`${envVarPrefix}_OKTA_ISSUER`],
  OKTA_CLIENT_ID: import.meta.env[`${envVarPrefix}_OKTA_CLIENT_ID`],
  OKTA_BASE_URL: import.meta.env[`${envVarPrefix}_OKTA_BASE_URL`],
  FEDERATED_GATEWAY_URL: import.meta.env[`${envVarPrefix}_FEDERATED_GATEWAY_URL`],
  CONFIGCAT_SDK_KEY: import.meta.env[`${envVarPrefix}_CONFIGCAT_SDK_KEY`],
  CONFIGCAT_OVERRIDES: parseJsonEnvVar(import.meta.env[`${envVarPrefix}_CONFIGCAT_OVERRIDES`]),
  CONFIGCAT_CACHE_TTL: import.meta.env[`${envVarPrefix}_CONFIGCAT_CACHE_TTL`],
  LOCIZE_PROJECTID: import.meta.env[`${envVarPrefix}_LOCIZE_PROJECTID`],
  LOCIZE_APIKEY: import.meta.env[`${envVarPrefix}_LOCIZE_APIKEY`],
  MAPBOX_ACCESS_TOKEN: import.meta.env[`${envVarPrefix}_MAPBOX_ACCESS_TOKEN`],
  LOCIZE_REFLNG: import.meta.env[`${envVarPrefix}_LOCIZE_REFLNG`],
  LOCIZE_VERSION: import.meta.env[`${envVarPrefix}_LOCIZE_VERSION`],
  IS_PRODUCTION: import.meta.env[`${envVarPrefix}_IS_PRODUCTION`],
  USE_GRAPHQL_DEMO_DATA: parseBoolEnvVar(import.meta.env[`${envVarPrefix}_USE_GRAPHQL_DEMO_DATA`]),
  DOCUMENT_DECRYPTION_URL: import.meta.env[`${envVarPrefix}_DOCUMENT_DECRYPTION_URL`],
  BUYER_FEE_PER_UNIT: import.meta.env[`${envVarPrefix}_BUYER_FEE_PER_UNIT`],
  SELLER_FEE_PER_UNIT: import.meta.env[`${envVarPrefix}_SELLER_FEE_PER_UNIT`],
  BUYER_FEE: import.meta.env[`${envVarPrefix}_BUYER_FEE`],
  SELLER_FEE: import.meta.env[`${envVarPrefix}_SELLER_FEE`],
  GST: import.meta.env[`${envVarPrefix}_GST`],
  APOLLO_GRAPH_VARIANT: import.meta.env[`${envVarPrefix}_APOLLO_GRAPH_VARIANT`],
  NODE_ENV: import.meta.env.NODE_ENV,
  secret: "#P0w3r_L3dg3r53124",
};

// Swap out any build environment variables with what is in the Kubernetes configmap.
// Variables are defined and found in the tracex-kubernetes repo's patch-configmap.yaml files.
if (window.appConfig) {
  Object.assign(buildConfig, Object.fromEntries(Object.entries(window.appConfig).filter(([_, value]) => !!value)));
}

export const SECRET = buildConfig.secret;
export const OKTA_ISSUER = buildConfig.OKTA_ISSUER;
export const OKTA_CLIENT_ID = buildConfig.OKTA_CLIENT_ID;
export const OKTA_BASE_URL = buildConfig.OKTA_BASE_URL;
export const FEDERATED_GATEWAY_URL = buildConfig.FEDERATED_GATEWAY_URL;
export const CONFIGCAT_SDK_KEY = String(buildConfig.CONFIGCAT_SDK_KEY);
export const CONFIGCAT_OVERRIDES = buildConfig.CONFIGCAT_OVERRIDES;
export const CONFIGCAT_CACHE_TTL = buildConfig.CONFIGCAT_CACHE_TTL;
export const LOCIZE_PROJECTID = buildConfig.LOCIZE_PROJECTID;
export const LOCIZE_APIKEY = buildConfig.LOCIZE_APIKEY;
export const LOCIZE_REFLNG = buildConfig.LOCIZE_REFLNG;
export const LOCIZE_VERSION = buildConfig.LOCIZE_VERSION;
export const MAPBOX_ACCESS_TOKEN = buildConfig.MAPBOX_ACCESS_TOKEN;
export const NODE_ENV = buildConfig.NODE_ENV;
export const IS_PRODUCTION = buildConfig.IS_PRODUCTION;
export const DOCUMENT_DECRYPTION_URL = buildConfig.DOCUMENT_DECRYPTION_URL;
export const BUYER_FEE_PER_UNIT = buildConfig.BUYER_FEE_PER_UNIT;
export const GST = buildConfig.GST;
export const SELLER_FEE_PER_UNIT = buildConfig.SELLER_FEE_PER_UNIT;
export const BUYER_FEE = buildConfig.BUYER_FEE;
export const SELLER_FEE = buildConfig.SELLER_FEE;
export const APOLLO_GRAPH_VARIANT = buildConfig.APOLLO_GRAPH_VARIANT;
export const USE_GRAPHQL_DEMO_DATA = buildConfig.USE_GRAPHQL_DEMO_DATA;
export const IS_TEST = false;
