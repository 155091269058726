/**
 * Converts any falsy value in object to default Value provided or null
 * @param values
 * @param toValue
 * @param ignoreValues list of falsy values to ignore
 * @returns
 */
export const convertFalsyToDefaultValue = (
  values: Record<string, any>,
  toValue: any = null,
  ignoreValues: any[] = [false],
) => {
  const newObject = { ...values };
  for (const key in values) {
    const correspondingValue = values[key];
    if (correspondingValue === toValue) continue;

    if (typeof correspondingValue !== "object" || correspondingValue === null) {
      newObject[key] = ignoreValues.includes(correspondingValue) ? correspondingValue : correspondingValue || toValue;
      continue;
    }
    if (Array.isArray(correspondingValue)) continue;
    const obj = convertFalsyToDefaultValue(correspondingValue, toValue);
    newObject[key] = obj;
  }
  return newObject;
};
