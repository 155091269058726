import { Box, Flex, Label, Text, ThemeUIStyleObject, Tooltip } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import FieldErrorMessage from "../error-message";

export type FormFieldLabelProps = {
  name?: string;
  hasErrorMessage?: boolean;
  label?: string;
  suffixLabel?: string | JSX.Element;
  wrapLabel?: boolean;
  small?: boolean;
  sx?: ThemeUIStyleObject;
  info?: string;
};

export const FormFieldLabel: FC<FormFieldLabelProps> = ({
  name = "",
  hasErrorMessage = true,
  label,
  suffixLabel,
  wrapLabel = false,
  info,
  sx = {},
  children,
}) => {
  const { t } = useTranslation();
  const labelSplit = label?.split("*");
  return (
    <Label id={name} sx={sx}>
      <Flex
        sx={{ alignItems: "center", justifyContent: "space-between", gap: 3, flexShrink: wrapLabel ? 1 : 0, mb: 3 }}
      >
        <Flex sx={{ flexDirection: "row" }}>
          {labelSplit?.[0]}
          <Text sx={{ color: "negative", ml: 1 }}>{label?.includes("*") ? "*" : ""}</Text>
          {info && (
            <Box as={"span"}>
              <Tooltip
                id={`form-field-label-${label}-tooltip`}
                content={<Text>{t(info)}</Text>}
                sx={{
                  ml: 1,
                }}
              />
            </Box>
          )}
        </Flex>
        {typeof suffixLabel === "string" ? <Text>{suffixLabel}</Text> : <Box as={"span"}>{suffixLabel}</Box>}
      </Flex>
      {children}
      {name && hasErrorMessage && <FieldErrorMessage name={name} sx={{ textAlign: "left", mt: 3 }} />}
    </Label>
  );
};

export default FormFieldLabel;
