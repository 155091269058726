import "./wdyr";
import "./fonts.css";
import "./app/lib/custom-yup-methods";
import "react-toastify/dist/ReactToastify.css";

import { ApolloProvider } from "@apollo/client";
import { PLCLProvider } from "@powerledger/ui-component-lib";
import React, { Suspense } from "react";
import ReactDom from "react-dom";
import { ToastContainer } from "react-toastify";

import App from "./app/app";
import { LoadingOverlayIndicator } from "./app/components";
import { GlobalErrorBoundary } from "./app/components/error-boundary";
import { ModalProvider } from "./app/components/modal-provider/modal-provider";
import container from "./app/container";
import { traceccTheme } from "./app/container/theme-override";

ReactDom.render(
  <React.StrictMode>
    <GlobalErrorBoundary>
      <ApolloProvider client={container.apolloClient}>
        <PLCLProvider themeOverwrites={traceccTheme}>
          <Suspense fallback={<LoadingOverlayIndicator />}>
            <ModalProvider>
              <ToastContainer />
              <App />
            </ModalProvider>
          </Suspense>
        </PLCLProvider>
      </ApolloProvider>
    </GlobalErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root"),
);
