import { Box, Button, EmailSVG, Flex, Heading } from "@powerledger/ui-component-lib";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { ErrorMessage, Form, Input, PasswordInput } from "@/app/components";

import { LoginViewProps } from "./login.types";

export const LoginView: React.FC<LoginViewProps> = ({ handleLogin, goToPage }) => {
  const { t } = useTranslation();

  return (
    <Flex
      sx={{
        minHeight: "100vh",
        background: 'url("/assets/loginBackground.svg")',
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom center",
        backgroundAttachment: "fixed",
        backgroundSize: ["auto", null, null, null, null, "cover"],
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Flex
        sx={{
          backgroundColor: "rgba(40, 39, 80, 0.6);",
          width: "350px",
          borderRadius: "16px",
          backdropFilter: "blur(6px)",
          border: "1px solid #282750",
          py: 4,
        }}
      >
        <Flex
          sx={{
            px: 4,
            py: 4,
            flex: 1,
            flexBasis: "0px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Heading
            as="h4"
            sx={{
              textAlign: "center",
              fontSize: [3, 4],
              mb: 2,
            }}
          >
            {t("TraceCC Admin")}
          </Heading>

          <Formik
            initialValues={{
              username: "",
              password: "",
            }}
            validationSchema={Yup.object().shape({
              username: Yup.string().required(t("Username is required")),
              password: Yup.string().required(t("Password is required")),
            })}
            onSubmit={handleLogin}
            validateOnMount
          >
            {({ handleSubmit, isSubmitting, isValid, status, setFieldValue, submitForm }) => (
              <>
                {!!status && <Box sx={{ color: "yellow", mt: 3, textAlign: "center" }}>{t(status)}</Box>}
                <Form onSubmit={handleSubmit} sx={{ mt: 4, fontSize: 1 }}>
                  <Form.Item>
                    <Input
                      placeholder={t("Username")}
                      autoCapitalize="none"
                      prefix={{
                        component: (
                          <Flex
                            sx={{
                              height: "18px",
                              width: "18px",
                            }}
                          >
                            <EmailSVG width="18" color="#fff" />
                          </Flex>
                        ),
                      }}
                      onInput={(e) => {
                        setFieldValue("username", e.currentTarget.value);
                      }}
                      sx={{
                        backgroundColor: "#282750",
                        height: 50,
                      }}
                    />
                    <ErrorMessage name="username" sx={{ mt: 1, ml: 2 }} />
                  </Form.Item>

                  <Form.Item>
                    <PasswordInput
                      onInput={(e) => {
                        setFieldValue("password", e.currentTarget.value);
                      }}
                      placeholder={t("Password")}
                      disabled={isSubmitting}
                      sx={{ backgroundColor: "#282750", height: 50 }}
                    />
                    <ErrorMessage name="password" sx={{ mt: 1, ml: 2 }} />
                  </Form.Item>
                  <Flex
                    sx={{
                      width: "100%",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Button
                      type="submit"
                      variant="primary"
                      sx={{ width: "75%", display: "block", mb: 3 }}
                      onClick={submitForm}
                      disabled={isSubmitting || !isValid}
                    >
                      {t("Sign in")}
                    </Button>
                  </Flex>
                </Form>
                <Flex sx={{ mt: 2, ml: 2, justifyContent: "center", width: "100%" }}>
                  <Button
                    as="a"
                    variant="underlined"
                    sx={{ fontSize: "0.8rem" }}
                    onClick={() => goToPage("/forgot-password")}
                    disabled={isSubmitting}
                  >
                    {t("Forgot password?")}
                  </Button>
                </Flex>
              </>
            )}
          </Formik>
        </Flex>
      </Flex>
    </Flex>
  );
};
