import { startCase } from "lodash";

import { AppDateFormats, formatDate } from "@/app/lib/format-date";

import { SearchTerms } from "./accounts-table.types";

export default function (searchTerms: SearchTerms) {
  let name = "";
  name += `${startCase(searchTerms.membershipType?.toLowerCase() ?? "All")}-`;
  if (searchTerms.membershipId) {
    name += `${searchTerms.membershipId}-`;
  }
  name += `Accounts (${formatDate(new Date().toString(), {
    formatStyle: AppDateFormats.UniversalDateTimeFormat,
  })}).csv`;
  return name;
}
