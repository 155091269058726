import { camelCase, startCase } from "lodash";

import { ClearingRecordStatus } from "@/app/types/generated/graphql";

export const orderOptions = [
  { label: "Status", value: null },
  ...Object.values(ClearingRecordStatus)
    .filter((status) => status !== ClearingRecordStatus.Completed)
    .map((status) => ({
      label: startCase(camelCase(status)),
      value: status,
    })),
];

export const mapStatusToAction = (status?: ClearingRecordStatus) => {
  let actionStatus: ClearingRecordStatus,
    modalText = "",
    actionText = "";
  if (status === ClearingRecordStatus.SellerInvoicePending) {
    actionText = "Approve Invoice";
    actionStatus = ClearingRecordStatus.SellerInvoiceReceived;
    modalText = "Are you sure the invoice is verified?";
  } else if (status === ClearingRecordStatus.PayInFailure) {
    actionText = "Proceed";
    actionStatus = ClearingRecordStatus.PayInSuccess;
    modalText = "Do you wish to proceed?";
  } else if (status === ClearingRecordStatus.CommodityTransferPending) {
    actionText = "Complete Transfer";
    actionStatus = ClearingRecordStatus.CommodityTransferSuccess;
    modalText = "Are you sure you want the commodity transfer is successful?";
  } else if (status === ClearingRecordStatus.PayOutFailure) {
    actionText = "Proceed";
    actionStatus = ClearingRecordStatus.PayOutSuccess;
    modalText = "Do you wish to proceed?";
  } else {
    return { actionText: "", actionStatus: undefined, modalText: "" };
  }
  return { actionText, actionStatus, modalText };
};
