import { tenantsQuery } from "./tenants";

export const typePolicies = {
  typePolicies: {
    Query: {
      fields: {
        tenants: tenantsQuery,
      },
    },
  },
};
