import { ActionButton, Box, Button, Heading, IconLoadingOutlined, Text } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { PublicLayout } from "@/app/components/public-layout";

import { ForgotPasswordViewProps } from "./forgot-password.types";
import { ForgotPasswordEmailForm } from "./forgot-password-email-form";

export const ForgotPasswordView: React.FC<ForgotPasswordViewProps> = ({
  email,
  handleForgotPassword,
  loading,
  goBack,
}) => {
  const { t } = useTranslation();
  const render = !email ? (
    <ForgotPasswordEmailForm onSuccess={handleForgotPassword} />
  ) : (
    <Box sx={{ display: ["block", "flex"], alignItems: "center" }}>
      <Button
        sx={{ px: 5, py: 3, mr: 2 }}
        variant="pill"
        disabled={loading}
        onClick={() => handleForgotPassword(email)}
      >
        {loading ? <IconLoadingOutlined /> : <span>{t(`Send it again`)}</span>}
      </Button>
      {!loading && <Text sx={{ color: "positive" }}>{t("Success")}</Text>}
    </Box>
  );

  return (
    <PublicLayout>
      <ActionButton onClick={goBack} sx={{ mt: [3] }}>
        {t(email ? "Back" : "Back to login")}
      </ActionButton>
      <Heading
        sx={{
          fontSize: !email ? [3, 4, 5, 6] : 4,
          fontWeight: !email ? "bold" : "light",
          lineHeight: 1,
          mb: 3,
          wordWrap: "break-word",
          fontFamily: "MintGroteskV08",
        }}
      >
        {t(!email ? "Forgot Password!" : `Email has been sent to ${email}`)}
      </Heading>
      {render}
    </PublicLayout>
  );
};
