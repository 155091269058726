import { NormalizedCacheObject } from "@apollo/client";
import { ApolloClient as ApolloClientType } from "@apollo/client/core/ApolloClient";
import OktaAuth from "@okta/okta-auth-js";

import Api, { ApiType } from "./api";
import ApolloClient from "./apollo-client";
import notifications, { NotificationsType } from "./notification-handler";
import oktaAuthClient from "./okta";

export type ContainerType = {
  api: ApiType;
  apolloClient: ApolloClientType<NormalizedCacheObject>;
  notifications: NotificationsType;
  oktaAuth: OktaAuth;
};

export const container: ContainerType = {
  api: Api(),
  apolloClient: ApolloClient(),
  notifications,
  oktaAuth: oktaAuthClient,
};

export default container;
