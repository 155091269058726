import { useEffect } from "react";

export const handlePreventRefresh = (event: BeforeUnloadEvent) => {
  event.preventDefault();
  event.returnValue = "";
  return false;
};

export const usePreventRefresh = () => {
  /**
   * Remind User before refresh to prevent them from losing data
   */
  useEffect(() => {
    window.addEventListener("beforeunload", handlePreventRefresh);
    return () => {
      window.removeEventListener("beforeunload", handlePreventRefresh);
    };
  }, []);
};
