import React, { Suspense } from "react";

import { getAccessToken } from "@/app/container/apollo-client";

import { LoadingOverlayIndicator } from "../../loading-overlay-indicator";
import { GlobalErrorBoundaryState } from "./global-error-boundary.types";
import GlobalErrorBoundaryView from "./global-error-boundary-error-view";

export class GlobalErrorBoundary extends React.Component<any, GlobalErrorBoundaryState> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false, error: "", isLoggedIn: false };
  }

  setIsLoggedIn = () => {
    getAccessToken()
      .then((token) => {
        this.setState({
          isLoggedIn: !!token,
        });
      })
      .catch((e) => {
        this.setState((state) => ({
          ...state,
          hasError: true,
          error: e,
        }));
      });
  };

  componentDidMount(): void {
    this.setIsLoggedIn();
    window.addEventListener("storage", this.setIsLoggedIn);
  }

  componentWillUnmount(): void {
    window.removeEventListener("storage", this.setIsLoggedIn);
  }

  componentDidCatch(error: any) {
    this.setState({
      hasError: true,
      error: error?.message,
    });
  }
  render() {
    return (
      <Suspense fallback={<LoadingOverlayIndicator />}>
        <GlobalErrorBoundaryView
          hasError={this.state.hasError}
          error={this.state.error}
          isLoggedIn={this.state.isLoggedIn}
        >
          {this.props.children}
        </GlobalErrorBoundaryView>
      </Suspense>
    );
  }
}
