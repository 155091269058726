import { commonErrorInput, Text, ThemeUIStyleObject } from "@powerledger/ui-component-lib";
import { FormikErrors, FormikTouched, getIn } from "formik";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";

export type InputType = "input" | "datepicker" | "select";
export type ErrorInputVariant = "errorInput" | "errorInputHighlighted";

export const FieldSelectors = {
  input: commonErrorInput,
  select: { 'div[class*="container"]>div': { ...commonErrorInput, borderStyle: "" } },
  datepicker: { ".react-datepicker__input-container input": commonErrorInput },
};

export interface FormInputErrorReturn {
  getInputStyles: (name: string, type?: InputType, customState?: Record<string, any>) => ThemeUIStyleObject;
  getInputVariant: (name: string, errorVariant?: ErrorInputVariant) => ErrorInputVariant | "input";
  getRequiredFieldsMessage: () => JSX.Element | null;
}

export function fieldHasError<A>(errors: FormikErrors<A>, touched: FormikTouched<A>, name: string): boolean {
  return Boolean(getIn(errors, name)) && Boolean(getIn(touched, name));
}

export function allErrorFieldsTouched(errors: Record<string, any>, touched: Record<string, any>): boolean {
  const errorKeys = Object.keys(errors);
  if (errorKeys.length === 0) {
    return false;
  }

  for (const errorInput of errorKeys) {
    if (!touched[errorInput]) {
      return false;
    }
    if (typeof errors[errorInput] === "object") {
      if (!allErrorFieldsTouched(errors[errorInput], touched[errorInput])) {
        return false;
      }
    }
  }

  return true;
}

export function useFormikInputError<A>(
  errors: FormikErrors<A>,
  touched: FormikTouched<A>,
  pageTouched?: boolean,
): FormInputErrorReturn {
  const { t } = useTranslation();

  const getInputStyles = useCallback(
    (name: string, type: InputType = "input", customState: Record<string, any> = {}) => {
      const hasErrorInField = fieldHasError(
        {
          ...errors,
          ...customState,
        },
        touched,
        name,
      );
      return pageTouched && hasErrorInField ? FieldSelectors[type] : hasErrorInField ? FieldSelectors[type] : {};
    },
    [errors, touched, pageTouched],
  );

  const getInputVariant = useCallback(
    (name: string, errorVariant: ErrorInputVariant = "errorInputHighlighted") => {
      const hasErrorInField = fieldHasError(errors, touched, name);
      return pageTouched && hasErrorInField ? errorVariant : hasErrorInField ? errorVariant : "input";
    },
    [errors, touched, pageTouched],
  );

  const getRequiredFieldsMessage = useCallback(() => {
    if (allErrorFieldsTouched(errors, touched)) {
      return (
        <Text sx={{ color: "warning", fontWeight: "bold" }}>
          {t("All fields marked with an asterisk(*) are required to process your application")}
        </Text>
      );
    }

    return null;
  }, [errors, touched, t]);

  return { getInputStyles, getInputVariant, getRequiredFieldsMessage };
}
