import { Theme, ThemeUIStyleObject } from "@powerledger/ui-component-lib";

export const CustomThemeProperty = <T extends string>(
  key: T & (T extends keyof Theme ? "Value is reserved by Theme!" : string),
  value: ThemeUIStyleObject,
) => value;

export const toastTraceCC = {
  ".Toastify__toast": {
    color: "black",
    borderLeftWidth: "10px",
    borderLeftStyle: "solid",
    backgroundColor: "white",
    minWidth: "350px",
    marginLeft: "-30px",
  },
  ".Toastify__toast--info": {
    borderLeftColor: "#605BC8",
  },
  ".Toastify__toast--success": {
    borderLeftColor: "#00B19F",
  },
  ".Toastify__toast--error": {
    borderLeftColor: "#DA1E28",
  },
  ":root ": {
    "--toastify-icon-color-info": "#605BC8",
    "--toastify-icon-color-success": "#00B19F",
    "--toastify-icon-color-error": "#DA1E28",
  },
};

export const reactQuill = {
  ".ql-container.ql-snow": {
    border: "1px solid white",
    backgroundColor: "background",
    borderBottomLeftRadius: "8px",
    borderBottomRightRadius: "8px",
  },
  ".ql-toolbar.ql-snow": {
    backgroundColor: "background",
    color: "white",
    border: "1px solid text",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
  },
  ".ql-snow.ql-toolbar .ql-picker-item.ql-selected": {
    color: "primary",
  },
  ".ql-snow.ql-toolbar .ql-picker-label.ql-active, .ql-snow.ql-toolbar button.ql-active": {
    color: "primary",
  },
  ".ql-snow.ql-toolbar button.ql-active .ql-stroke, .ql-snow.ql-toolbar button:hover, .ql-snow.ql-toolbar button:hover .ql-stroke":
    {
      stroke: "primary",
      color: "primary",
    },
  ".ql-snow.ql-toolbar .ql-picker-label:hover, .ql-snow.ql-toolbar .ql-picker-item:hover": {
    color: "primary",
  },
  ".ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke": {
    stroke: "primary",
  },
  ".ql-snow .ql-editor": {
    minHeight: "200px",
  },
  ".ql-snow .ql-stroke": {
    fill: "none",
    stroke: "text",
  },
  ".ql-snow .ql-fill": {
    fill: "text",
    stroke: "none",
  },
  ".ql-snow .ql-picker": {
    color: "text",
    backgroundColor: "background",
  },
  ".ql-snow .ql-picker-options": {
    backgroundColor: "background",
  },
};

export const texts = CustomThemeProperty("texts", {
  traceccDefaultHeading: {
    fontWeight: "bolder",
    fontSize: "2.3rem",
    padding: "10px 0",
  },
});

export const containedTableStyle: ThemeUIStyleObject = {
  borderRadius: "8px",
  background: "#232245",
  overflowY: "auto",
  thead: {
    background: "#232245",
    tr: {
      th: {
        background: "#232245",
      },
    },

    tbody: {
      tr: {
        th: {
          background: "#232245",
        },
      },
    },
  },
};

export const traceccTheme = {
  texts,
  styles: {
    root: {
      ...toastTraceCC,
      ...reactQuill,
    },
  },
};
