import { alpha, Flex } from "@powerledger/ui-component-lib";

import { LogoCarbonDeviceSVG } from "@/assets/logos";

export const LoadingOverlayIndicator = () => (
  <Flex
    sx={{
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 999,
      display: "flex",
      position: "fixed",
      alignItems: "center",
      justifyContent: "center",
      bg: alpha("background", 0.95),
    }}
  >
    <LogoCarbonDeviceSVG />
  </Flex>
);
