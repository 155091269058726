import { IconRefreshCircle } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";
import { Button } from "theme-ui";

export function RefreshIconButton({
  loading = false,
  refreshKey = "",
  fetchData,
}: {
  refreshKey?: string;
  loading?: boolean;
  fetchData: () => void;
}) {
  const { t } = useTranslation();
  return (
    <Button
      disabled={loading}
      sx={{ opacity: loading ? 0.5 : 1 }}
      variant="text"
      onClick={fetchData}
      aria-label={t("refresh " + refreshKey)}
    >
      <IconRefreshCircle size={6} />
    </Button>
  );
}
