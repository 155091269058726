import { startCase } from "lodash";
import { useCallback, useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { defaultAddress } from "@/app/components/address-form-section";
import { BankDetails } from "@/app/components/bank-form-fields/bank-form-fields.types";
import { ModalContext } from "@/app/components/modal-provider/modal-provider";
import notifications from "@/app/container/notification-handler";
import { usePreventRefresh } from "@/app/hooks/use-prevent-refresh";
import { convertFalsyToDefaultValue } from "@/app/lib/convert-falsy-values-to-default-value";
import {
  AccountDetailsDocument,
  AccountDetailsQuery,
  AccountsDocument,
  AddressInput,
  BankType,
  ClientsDocument,
  EditCcAccountInput,
  MemberInput,
  MembershipType,
  OnboardUserByAdminInput,
  useAccountDetailsQuery,
  useAddClientMutation,
  useEditCcAccountDetailsMutation,
  useEditClientDetailsMutation,
  useOnboardUserByAdminMutation,
} from "@/app/types/generated/graphql";

import {
  AddressFieldDependency,
  CreateEditAccountFormFields,
  CreateEditAccountProps,
  DependentAddressFields,
  UseCreateEditAccountFn,
} from "./create-edit-account.types";

export const membershipTypeOptions = Object.values(MembershipType).map((type) => ({
  label: startCase(type.toLocaleLowerCase()),
  value: type,
}));

export const getInitialDataForForm = (account?: AccountDetailsQuery["accounts"]["0"], opts?: { isClient: boolean }) => {
  const omitIdAndTypeName = (values?: any, omitId = true, omitTypeName = true) => {
    const { id, __typename, ...rest } = values || {};
    if (!omitId) {
      rest.id = id;
    }
    if (!omitTypeName) {
      rest.__typename = __typename;
    }
    return rest;
  };
  const settlementBank = account?.banks?.find((bank) => bank.type === BankType.Settlement) || {
    accountNumber: "",
    bankName: "",
    branchAddress: "",
    ifsCode: "",
    swiftCode: "",
    bankCode: "",
  };
  const fundBank = account?.banks?.find((bank) => bank.type === BankType.Fund) || {
    accountNumber: "",
    bankName: "",
    branchAddress: "",
    ifsCode: "",
    swiftCode: "",
    bankCode: "",
  };

  const {
    id,
    membershipType,
    registries,
    invoiceEmail,
    invoiceContactNumber,
    membershipId,
    name,
    registeredAddress,
    businessAddress,
    invoiceAddress,
    gstRegistrationAddress,
    gstin,
    panNumber,
    tanNumber,
  } = account?.members?.[0] ?? {};

  const isClient = opts?.isClient || membershipType === MembershipType["Client"];

  return {
    id: id,
    membershipType: membershipType ?? MembershipType["Proprietor"],
    registries: registries?.map((reg) => omitIdAndTypeName(reg, false, true)) ?? [],
    ...(!isClient
      ? {
          contactNumber: account?.contactNumber ?? "",
          email: account?.email ?? "",
        }
      : {}),
    invoiceEmail: invoiceEmail ?? "",
    invoiceContactNumber: invoiceContactNumber ?? "",
    membershipId: membershipId ?? "",
    name: name ?? "",
    registeredAddress: omitIdAndTypeName(registeredAddress ?? defaultAddress, false),
    businessAddress: omitIdAndTypeName(businessAddress ?? defaultAddress, false),
    invoiceAddress: omitIdAndTypeName(invoiceAddress ?? defaultAddress, false),
    gstRegistrationAddress: gstRegistrationAddress ? omitIdAndTypeName(gstRegistrationAddress, false, true) : null,
    gstin: gstin ?? null,
    panNumber: panNumber ?? null,
    tanNumber: tanNumber ?? null,
    ...(!isClient
      ? {
          settlementBank: omitIdAndTypeName(settlementBank, false, true),
          fundBank: omitIdAndTypeName(fundBank, false, true),
        }
      : {}),
    addressFieldDependencyRelations: {
      businessAddress: AddressFieldDependency.none,
      gstRegistrationAddress: AddressFieldDependency.none,
      invoiceAddress: AddressFieldDependency.none,
    },
  } as CreateEditAccountFormFields;
};

const getFieldsTouched = (values: Record<string, any>, touched: Record<string, any> = {}) => {
  for (const key in values) {
    if (typeof values[key] === "object") {
      getFieldsTouched(values[key], touched);
    } else {
      touched[key] = true;
    }
  }
  return touched;
};

export const getBankFormDisabledFields = (isEdit: boolean, initialBankDetails: BankDetails) => {
  return {
    accountNumber: isEdit,
    bankName: isEdit,
    branchAddress: isEdit,
    ifsCode: Boolean(isEdit && initialBankDetails?.ifsCode),
    swiftCode: Boolean(isEdit && initialBankDetails?.swiftCode),
    bankCode: Boolean(isEdit && initialBankDetails?.bankCode),
  };
};

/**
 * Retrieves all address field values, resolving dependencies.
 *
 * @param values - The values from the create/edit account form including all the addresses and the dependency relation mapper.
 * @return The record of all address field values after resolving their dependencies.
 */
export const getAllAddressFieldValuesResolvingDependencies = (values: CreateEditAccountFormFields) => {
  const {
    addressFieldDependencyRelations,
    registeredAddress,
    businessAddress,
    invoiceAddress,
    gstRegistrationAddress,
  } = values;

  const allAddressFieldValues: Record<DependentAddressFields | "registeredAddress", AddressInput> = {
    registeredAddress,
    businessAddress,
    invoiceAddress,
    gstRegistrationAddress,
  };

  // Handle from higher level to lower level
  // Handle for businessAddress
  if (addressFieldDependencyRelations.businessAddress === AddressFieldDependency.registeredAddress) {
    allAddressFieldValues.businessAddress = { ...registeredAddress, id: values.businessAddress.id };
  }

  // Handle for invoiceAddress
  if (addressFieldDependencyRelations.invoiceAddress !== AddressFieldDependency.none) {
    allAddressFieldValues.invoiceAddress = {
      ...allAddressFieldValues[addressFieldDependencyRelations.invoiceAddress],
      id: values.invoiceAddress.id,
    };
  }

  // Handle for gstRegistrationAddress
  if (addressFieldDependencyRelations.gstRegistrationAddress !== AddressFieldDependency.none) {
    allAddressFieldValues.gstRegistrationAddress = {
      ...allAddressFieldValues[addressFieldDependencyRelations.gstRegistrationAddress],
      id: values.gstRegistrationAddress.id,
    };
  }

  return allAddressFieldValues;
};

export const saveAccountSubmitCallback = async ({
  values,
  accountId,
  onboardUser,
  addClient,
  editAccount,
  editClient,
  goBack,
}: {
  values: CreateEditAccountFormFields;
  accountId?: string;
  onboardUser: ReturnType<typeof useOnboardUserByAdminMutation>["0"];
  addClient: ReturnType<typeof useAddClientMutation>["0"];
  editAccount: ReturnType<typeof useEditCcAccountDetailsMutation>["0"];
  editClient: ReturnType<typeof useEditClientDetailsMutation>["0"];
  goBack: () => void;
}) => {
  const allAddresses = getAllAddressFieldValuesResolvingDependencies(values);
  const { addressFieldDependencyRelations, ...formData } = { ...values, ...allAddresses };

  if (formData.membershipType == MembershipType["Client"]) {
    if (formData.id) {
      await editClient({
        variables: {
          input: {
            accountId,
            client: convertFalsyToDefaultValue(formData) as MemberInput,
          },
        },
      });
    } else
      await addClient({
        variables: {
          input: {
            accountId,
            client: convertFalsyToDefaultValue(formData) as MemberInput,
          },
        },
      });
  } else {
    const { contactNumber, email, settlementBank, fundBank, ...members } = formData;
    if (formData.id) {
      await editAccount({
        variables: {
          input: {
            accountId,
            banks: [
              { ...convertFalsyToDefaultValue(settlementBank), type: BankType.Settlement },
              { ...convertFalsyToDefaultValue(fundBank), type: BankType.Fund },
            ],
            members: convertFalsyToDefaultValue(members),
            user: { contactNumber, email },
          } as EditCcAccountInput,
        },
      });
    } else {
      await onboardUser({
        variables: {
          input: {
            banks: [
              { ...convertFalsyToDefaultValue(settlementBank), type: BankType.Settlement },
              { ...convertFalsyToDefaultValue(fundBank), type: BankType.Fund },
            ],
            members: convertFalsyToDefaultValue(members),
            user: { contactNumber, email },
          } as OnboardUserByAdminInput,
        },
      });
    }
  }
  goBack();
};

export const useCreateEditAccount: UseCreateEditAccountFn = () => {
  const navigate = useNavigate();
  const [error, setError] = useState("");
  usePreventRefresh();
  const { t } = useTranslation();
  const params = useParams();
  const location = useLocation();

  const { showConfirmationModal } = useContext(ModalContext);

  const [onboardUser] = useOnboardUserByAdminMutation({
    refetchQueries: [AccountsDocument],
  });

  const [editAccount] = useEditCcAccountDetailsMutation({
    refetchQueries: [AccountDetailsDocument],
  });

  const [addClient] = useAddClientMutation({
    refetchQueries: [ClientsDocument],
  });

  const [editClient] = useEditClientDetailsMutation({
    refetchQueries: [ClientsDocument],
  });

  const type = new URLSearchParams(location.search).get("t");
  const isClientForm = type === "client";
  const member = location.state?.membershipId;
  const accountId = location.state?.accountId;

  const isEdit = params.id?.toLowerCase() !== "new";

  const { data, loading } = useAccountDetailsQuery({
    skip: !isEdit || !accountId,
    variables: {
      accountFilter: {
        id: accountId,
      },
      memberFilter: {
        id: params.id,
      },
    },
    onError(error) {
      setError(error?.message);
      notifications.error({
        description: t(error?.message),
      });
    },
  });

  const goBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const onSubmit: CreateEditAccountProps["onSubmit"] = useCallback(
    (values) => {
      const modalText = isEdit
        ? "Are you sure you wish to save the changes made?"
        : values.membershipType === MembershipType["Client"]
        ? "Are you sure you want to add the client?"
        : "Kindly verify all data before submitting as some of the data is non-editable after submission. Please confirm if you wish to proceed.";

      let notificationMessage = "";
      if (values.membershipType === MembershipType.Client) {
        notificationMessage = isEdit
          ? "Client details have been updated successfully."
          : "Client has been added successfully!";
      } else {
        notificationMessage = isEdit
          ? `Account details for member ID ${values.membershipId} has been updated successfully!`
          : "Member account is successfully created.";
      }

      showConfirmationModal({
        onSubmit: async () => {
          await saveAccountSubmitCallback({
            values,
            accountId,
            onboardUser,
            addClient,
            editAccount,
            editClient,
            goBack,
          });
        },
        modalText,
        messages: {
          successMessage: notificationMessage,
        },
      });
    },
    [accountId, showConfirmationModal, editClient, editAccount, addClient, onboardUser, goBack, isEdit],
  );

  const initialValues: CreateEditAccountFormFields = useMemo(
    () => ({
      ...getInitialDataForForm(data?.accounts?.[0], {
        isClient: isClientForm && member && accountId,
      }),
      // Handling only in case of client for now
      ...(isClientForm && member && accountId
        ? { membershipId: member, membershipType: MembershipType["Client"] }
        : {}),
    }),
    [data?.accounts, accountId, member, isClientForm],
  );

  const membershipOptions =
    (isClientForm && member && accountId) || initialValues.membershipType === MembershipType["Client"]
      ? membershipTypeOptions.filter((m) => m.value === MembershipType["Client"])
      : membershipTypeOptions.filter((m) => m.value !== MembershipType["Client"]);

  return {
    goBack,
    formikProps: {
      initialValues,
      initialTouched: isEdit ? getFieldsTouched(initialValues) : {},
    },
    loading,
    error,

    isEdit,
    onSubmit,
    membershipOptions,
  };
};
