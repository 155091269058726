import { LegacySelect } from "@powerledger/ui-component-lib";
import { useTranslation } from "react-i18next";

import { getSelectTranslation } from "../../lib/get-translations-for-components";
import { AccountsSelectProps } from "./accounts-select.types";
import { useAccountsSelect } from "./use-accounts-select";

export const AccountsSelect: React.FC<AccountsSelectProps> = ({
  onFieldChange,
  filter,
  selectedValue,
  onBlur,
  disabled,
  placeholder = "Enter Membership ID to Search...",
  optionFilter,
  ...props
}) => {
  const { cachedAccountsOptions, getMoreData, onCustomSearch, loadingAccountsData, baseAccountsOptions } =
    useAccountsSelect({
      filter,
      optionFilter,
    });
  const { t } = useTranslation();

  return (
    <LegacySelect
      {...props}
      translation={getSelectTranslation(t)}
      value={selectedValue}
      placeholder={t(placeholder)}
      options={cachedAccountsOptions}
      isPaginated
      getMoreData={getMoreData}
      disabled={disabled}
      onCustomSearch={onCustomSearch}
      hasMoreData={cachedAccountsOptions?.length < (baseAccountsOptions?.length || 0)}
      onChange={(option) => {
        option && onFieldChange(option);
      }}
      isLoading={loadingAccountsData}
      onBlur={onBlur}
    />
  );
};
