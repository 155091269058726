import { createContext, lazy, Suspense, useCallback, useMemo, useState } from "react";

import { CCDepositRequestsCustomProps } from "../cc-deposit-requests-modal/cc-deposit-requests-modal.types";
import { ConfirmCommodityExportCustomProps } from "../confirm-commodity-export-modal/confirm-commodity-export-modal.types";
import ConfirmDeleteTradingHaltModal from "../confirm-delete-trading-halt-modal/confirm-delete-trading-halt-modal";
import { ConfirmDeleteTradingHaltModalCustomProps } from "../confirm-delete-trading-halt-modal/confirm-delete-trading-halt-modal.types";
import ConfirmImportModal from "../confirm-import-modal/confirm-import-modal";
import { ConfirmImportModalCustomProps } from "../confirm-import-modal/confirm-import-modal.types";
import { ConfirmVerifyAccountModalCustomProps } from "../confirm-verify-account-modal";
import ConfirmationModal from "../confirmation-modals/confirmation-modal";
import { ConfirmationModalCustomProps } from "../confirmation-modals/confirmation-modal.types";
import EditTradingHaltModal from "../edit-trading-halt-modal.tsx/edit-trading-halt-modal";
import { EditTradingHaltModalCustomProps } from "../edit-trading-halt-modal.tsx/edit-trading-halt-modal.types";
import { LoadingOverlayIndicator } from "../loading-overlay-indicator";
import { ModalEnums, ModalHandler, ModalProviderProps, ModalState } from "./modal-provider.types";

const ContactSupportModal = lazy(() => import("../contact-support-modal/contact-support-modal"));
const ConfirmVerifyAccountModal = lazy(() => import("../confirm-verify-account-modal/confirm-verify-account-modal"));
const ConfirmCommodityExportModal = lazy(
  () => import("../confirm-commodity-export-modal/confirm-commodity-export-modal"),
);
const CCDepositRequestsModal = lazy(() => import("../cc-deposit-requests-modal/cc-deposit-requests-modal"));

export const ModalContext = createContext({} as ModalHandler);

export const ModalProvider: React.FC<ModalProviderProps> = ({ children }) => {
  const [modalState, setModalState] = useState<ModalState | null>(null);

  const renderModals = useMemo(() => {
    switch (modalState?.type) {
      case ModalEnums.ConfirmationModal:
        return (
          <ConfirmationModal
            visible={modalState?.type === ModalEnums.ConfirmationModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );

      case ModalEnums.ConfirmImport:
        return (
          <ConfirmImportModal
            visible={modalState?.type === ModalEnums.ConfirmImport}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );
      case ModalEnums.ConfirmCommodityExport:
        return (
          <ConfirmCommodityExportModal
            visible={modalState?.type === ModalEnums.ConfirmCommodityExport}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );
      case ModalEnums.ContactSupportModal:
        return (
          <ContactSupportModal
            visible={modalState?.type === ModalEnums.ContactSupportModal}
            closeModal={() => setModalState(null)}
          />
        );
      case ModalEnums.ConfirmVerifyAccountModal:
        return (
          <ConfirmVerifyAccountModal
            visible={modalState?.type === ModalEnums.ConfirmVerifyAccountModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );

      case ModalEnums.EditTradingHaltModal:
        return (
          <EditTradingHaltModal
            visible={modalState?.type === ModalEnums.EditTradingHaltModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );
      case ModalEnums.ConfirmDeleteTradingHaltModal:
        return (
          <ConfirmDeleteTradingHaltModal
            visible={modalState?.type === ModalEnums.ConfirmDeleteTradingHaltModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );

      case ModalEnums.CCDepositRequestsModal:
        return (
          <CCDepositRequestsModal
            visible={modalState?.type === ModalEnums.CCDepositRequestsModal}
            closeModal={() => setModalState(null)}
            {...modalState.customProps}
          />
        );

      default:
        return null;
    }
  }, [modalState]);

  const showContactSupportModal = useCallback(() => {
    setModalState({
      type: ModalEnums.ContactSupportModal,
      customProps: undefined,
    });
  }, []);

  const showConfirmVerifyAccountModal = useCallback((customProps: ConfirmVerifyAccountModalCustomProps) => {
    setModalState({
      type: ModalEnums.ConfirmVerifyAccountModal,
      customProps,
    });
  }, []);

  const showConfirmImportModal = useCallback((customProps: ConfirmImportModalCustomProps) => {
    setModalState({
      type: ModalEnums.ConfirmImport,
      customProps,
    });
  }, []);

  const showEditTradingHaltModal = useCallback((customProps: EditTradingHaltModalCustomProps) => {
    setModalState({
      type: ModalEnums.EditTradingHaltModal,
      customProps,
    });
  }, []);

  const showConfirmDeleteTradingHaltModal = useCallback((customProps: ConfirmDeleteTradingHaltModalCustomProps) => {
    setModalState({
      type: ModalEnums.ConfirmDeleteTradingHaltModal,
      customProps,
    });
  }, []);

  const showConfirmationModal = useCallback((customProps: ConfirmationModalCustomProps) => {
    setModalState({
      type: ModalEnums.ConfirmationModal,
      customProps,
    });
  }, []);

  const showConfirmCommodityExportModal = useCallback((customProps: ConfirmCommodityExportCustomProps) => {
    setModalState({
      type: ModalEnums.ConfirmCommodityExport,
      customProps,
    });
  }, []);

  const showCCDepositRequestsModal = useCallback((customProps: CCDepositRequestsCustomProps) => {
    setModalState({
      type: ModalEnums.CCDepositRequestsModal,
      customProps,
    });
  }, []);

  return (
    <ModalContext.Provider
      value={useMemo(
        () => ({
          showContactSupportModal,
          showConfirmVerifyAccountModal,
          showEditTradingHaltModal,
          showConfirmDeleteTradingHaltModal,
          showConfirmCommodityExportModal,
          showCCDepositRequestsModal,
          showConfirmImportModal,
          showConfirmationModal,
        }),
        [
          showConfirmationModal,
          showConfirmImportModal,
          showContactSupportModal,
          showConfirmDeleteTradingHaltModal,
          showConfirmCommodityExportModal,
          showEditTradingHaltModal,
          showConfirmVerifyAccountModal,
          showCCDepositRequestsModal,
        ],
      )}
    >
      <Suspense fallback={<LoadingOverlayIndicator />}>{renderModals}</Suspense>
      {children}
    </ModalContext.Provider>
  );
};
