import { BrowserRouter } from "react-router-dom";

import { AttributesTooltip, GenericTooltip } from "./components/tooltips";
import container from "./container";
import { AppRouter } from "./navigation/app-router";

export default function App() {
  return (
    <BrowserRouter>
      <AppRouter oktaAuth={container.oktaAuth} />
      <AttributesTooltip />
      <GenericTooltip />
    </BrowserRouter>
  );
}
