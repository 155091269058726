import { MockedProvider } from "@apollo/client/testing";
import OktaAuth from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import { PLCLProvider } from "@powerledger/ui-component-lib";
import { Suspense } from "react";
import { Router } from "react-router";

import { traceccTheme } from "@/app/container/theme-override";
import { appHistory } from "@/test-utils";

const oktaAuth = new OktaAuth({
  issuer: 'https://{yourOktaDomain}.com/oauth2/{authServerId}"',
  clientId: "OKTA_CLIENT_ID",
  redirectUri: window.location.origin + "/login/callback",
});

export const StoryBookWrapper = ({ children }: any) => (
  <Suspense fallback="Loading translations">
    <PLCLProvider themeOverwrites={traceccTheme}>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={() => {}}>
        <MockedProvider>
          <Router navigator={appHistory} location={appHistory.location}>
            {children}
          </Router>
        </MockedProvider>
      </Security>
    </PLCLProvider>
  </Suspense>
);
