import { Box, Flex, IconBell, IconButton, IconFastForward, Link, Menu, Sider } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { getSupportEmail } from "@/app/lib/get-support-email";
import { TraceCCAdminLogo, TraceCCLogoSmall } from "@/assets/logos/components";

import RouteButton from "../route-button";
import { LayoutSiderProps, NotificationBadgeProps } from "./layout-sider.types";

const NotificationBadge: FC<NotificationBadgeProps> = ({ unreadMessageCount }) =>
  unreadMessageCount ? (
    <Box
      sx={{
        bg: "secondary",
        borderRadius: 12,
        color: "white",
        px: 2,
        ml: 2,
      }}
    >
      {unreadMessageCount > 9 ? "9+" : unreadMessageCount}
    </Box>
  ) : null;

export const LayoutSider: FC<LayoutSiderProps> = ({
  sider,
  sidebarRoutes,
  sidebarOpen,
  unreadMessageCount,
  updateSidebarState,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const supportEmail = getSupportEmail();

  return (
    sider || (
      <Sider>
        <Flex
          sx={{
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <Box>
            <Flex
              sx={{
                flexDirection: sidebarOpen ? "row" : "column",
                height: sidebarOpen ? 80 : 100,
                alignItems: "center",
                textAlign: "center",
                px: sidebarOpen ? 4 : 0,
                pt: 2,
                ...(sidebarOpen ? {} : { justifyContent: "space-between" }),
              }}
            >
              <IconButton
                sx={{
                  width: "100%",
                  margin: "auto",
                  ...(sidebarOpen
                    ? {
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }
                    : { svg: { ml: "10px", scale: "1.3" } }),
                }}
                onClick={() => navigate("/accounts")}
              >
                {sidebarOpen && (
                  <Box sx={{ width: 5 }}>
                    <TraceCCAdminLogo />
                  </Box>
                )}
                {!sidebarOpen && (
                  <Flex
                    sx={{
                      flexDirection: "column",
                    }}
                  >
                    <TraceCCLogoSmall width={32} />
                    <Box
                      sx={{
                        fontSize: "0.5em",
                        color: "text",
                        fontWeight: 500,
                      }}
                    >
                      {t("TraceCC Admin")}
                    </Box>
                  </Flex>
                )}
              </IconButton>
              <IconButton
                sx={{
                  flexShrink: 0,
                  ...(sidebarOpen ? {} : { width: "100%" }),
                }}
                onClick={() => updateSidebarState()}
              >
                <IconFastForward
                  size={5}
                  color="text"
                  sx={{
                    transition: "base",
                    display: ["none", "none", "flex"],
                    ...(sidebarOpen ? { transform: "rotate(180deg)" } : {}),
                  }}
                />
              </IconButton>
            </Flex>
            <Box sx={{ mt: 4 }}>
              <Menu>{sidebarRoutes}</Menu>
            </Box>
          </Box>
          {!sidebarOpen && (
            <Box
              sx={{
                fontSize: 0,
                color: "textDarker",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <RouteButton
                to="/notifications"
                sx={{
                  width: "30px",
                  height: "30px",
                  margin: "auto",
                }}
                badge={<NotificationBadge unreadMessageCount={unreadMessageCount} />}
              >
                <IconBell size={5} />
              </RouteButton>
              <Box mt={2}>{t(`©{{date}}`, { date: new Date().getFullYear() })}</Box>
            </Box>
          )}
          {sidebarOpen && (
            <Box sx={{ p: 2, mt: 3 }}>
              <Flex sx={{ alignItems: "center", px: 4 }}>
                <RouteButton
                  sx={{
                    width: 220,
                  }}
                  to="/notifications"
                >
                  <IconBell size={5} />
                  {t("Notifications")}
                  <NotificationBadge unreadMessageCount={unreadMessageCount} />
                </RouteButton>
              </Flex>
              <Box sx={{ fontSize: 0, color: "text", mt: 4 }}>
                <Link
                  href={`mailto:${supportEmail}`}
                  target="_blank"
                  sx={{
                    color: "text",
                    textDecoration: "none",
                    "&:hover": { textDecoration: "underline" },
                  }}
                >
                  {t("Contact Us")}
                </Link>
                <Box>{t(`©{{date}} Powerledger. All rights reserved`, { date: new Date().getFullYear() })}</Box>
              </Box>
            </Box>
          )}
        </Flex>
      </Sider>
    )
  );
};
