import currency from "currency.js";

export const currencyFormatter = (
  symbol = "$",
  amount?: string | number | null,
  opts?: { precision?: number; fromCents?: boolean; useVedic?: boolean; noSymbol?: boolean },
) => {
  const { fromCents = true, useVedic = false, noSymbol = false, precision = 2 } = opts ?? {};
  if (amount === null || amount === undefined) {
    return "-";
  } else {
    return currency(amount.toString(), {
      fromCents: fromCents,
      symbol: noSymbol ? "" : `${symbol} `, // add space after symbol only if symbol has to be displayed
      useVedic: useVedic,
      precision: precision,
    }).format();
  }
};
