import { Box } from "theme-ui";

import { ChipProps } from "./chip.types";

export const Chip: React.FC<ChipProps> = ({ sx, children }) => (
  <Box
    sx={{
      bg: "secondary",
      color: "background",
      p: 1,
      fontSize: 1,
      textAlign: "center",
      borderRadius: 25,
      maxWidth: "100px",
      ...sx,
    }}
  >
    {children}
  </Box>
);
