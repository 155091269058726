import { Link } from "react-router-dom";
import { Box, Flex } from "theme-ui";

import { TraceCCAdminLogo } from "@/assets/logos";

import { PublicLayoutProps } from "./public-layout.types";

export const PublicLayout: React.FC<PublicLayoutProps> = ({ children }) => (
  <Box
    sx={{
      minHeight: "100vh",
      background: "url('/assets/publicBackground.svg')",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "bottom right",
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Flex
      sx={{
        alignItems: "center",
        textAlign: "center",
        px: 4,
        py: 2,
      }}
    >
      <Link to="/">
        <TraceCCAdminLogo />
      </Link>
    </Flex>
    <Box
      sx={{
        width: ["80%", 800],
        margin: "auto",
        mt: "5%",
      }}
    >
      {children}
    </Box>
  </Box>
);
