import { LegacySelect, LegacySwitch } from "@powerledger/ui-component-lib";
import { addHours } from "date-fns";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { Box, Button, Flex, Grid } from "theme-ui";
import * as Yup from "yup";

import { Form, FormFieldLabel, LocalDatePicker } from "@/app/components";
import { AppDateFormats } from "@/app/lib/format-date";
import { getSelectTranslation } from "@/app/lib/get-translations-for-components";

import { CreateOrEditTradingHaltViewProps } from "./create-or-edit-trading-halt.types";
import { getMinAndMaxTime } from "./use-create-or-edit-trading-halt";

/**
 * Props to offset datepicker popper
 * Helps in proper popper positioning in Modals due to the zIndex properties in modals
 */
const popperModifierProps = {
  popperModifiers: [
    {
      name: "offset",
      options: {
        offset: [5, 10],
      },
    },
    {
      name: "preventOverflow",
      options: {
        rootBoundary: "viewport",
        tether: false,
        altAxis: true,
      },
    },
  ],
};

export const CreateOrEditTradingHaltView: React.FC<CreateOrEditTradingHaltViewProps> = ({
  initialValues,
  tenantsOptions,
  tenantsLoading,
  onSubmit,
  onCancel,
  isEdit,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        p: 3,
      }}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        enableReinitialize
        validateOnMount
        initialTouched={
          isEdit
            ? {
                startDateTime: true,
                endDateTime: true,
                isApplicable: true,
                isPlanned: true,
                tenantId: true,
              }
            : {}
        }
        validationSchema={Yup.object().shape({
          startDateTime: Yup.string().required(),
          endDateTime: Yup.string().required(),
          isApplicable: Yup.boolean().required(),
          isPlanned: Yup.boolean().required(),
          tenantId: Yup.string().required(),
        })}
      >
        {({ values, isValid, setFieldValue, submitForm, isSubmitting, touched, errors }) => {
          const today = new Date();
          const [startMinTime, startMaxTime] = getMinAndMaxTime(values.startDateTime);

          const [endMinTime, endMaxTime] = getMinAndMaxTime(values.endDateTime, {
            addMinMinutes: 0,
            startDateTime: values.startDateTime,
          });

          return (
            <Form>
              <Grid gap={3} columns={[2, null, 3]} sx={{ alignItems: "baseline" }}>
                <Form.Item key="trading-halt-startDate" sx={{ justifyContent: "end" }}>
                  <FormFieldLabel
                    hasErrorMessage={false}
                    info={t("Date corresponds to your local date ({{timezone}})", {
                      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    })}
                    small
                    name="startDateTime"
                    label={t("Start Date Time")}
                    sx={{
                      div: {
                        ...(errors?.startDateTime && touched?.startDateTime
                          ? {
                              input: {
                                borderWidth: "1px",
                                borderColor: "warning",
                                borderStyle: "solid",
                              },
                            }
                          : {}),
                      },
                    }}
                  >
                    <LocalDatePicker
                      showTimeSelect
                      timeIntervals={1}
                      minTime={startMinTime}
                      maxTime={startMaxTime}
                      minDate={today}
                      dateFormat={AppDateFormats.UniversalDateTimeFormat}
                      shouldCloseOnSelect={false}
                      onChange={(date) => {
                        setFieldValue("startDateTime", date);

                        /** By Default Halt is created for 4 hours */
                        setFieldValue("endDateTime", addHours(new Date(date), 4));
                      }}
                      value={values.startDateTime || ""}
                      {...popperModifierProps}
                    />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="trading-halt-endDate" sx={{ justifyContent: "end" }}>
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    info={t("Date corresponds to your local date ({{timezone}})", {
                      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                    })}
                    name="endDateTime"
                    label={t("End Date Time")}
                    sx={{
                      div: {
                        ...(errors?.endDateTime && touched?.endDateTime
                          ? {
                              input: {
                                borderWidth: "1px",
                                borderColor: "warning",
                                borderStyle: "solid",
                              },
                            }
                          : {}),
                      },
                    }}
                  >
                    <LocalDatePicker
                      showTimeSelect
                      timeIntervals={1}
                      minTime={endMinTime}
                      maxTime={endMaxTime}
                      minDate={values.startDateTime ? new Date(values.startDateTime) : today}
                      dateFormat={AppDateFormats.UniversalDateTimeFormat}
                      shouldCloseOnSelect={false}
                      onChange={(date) => {
                        setFieldValue("endDateTime", date);
                      }}
                      value={values.endDateTime || ""}
                      {...popperModifierProps}
                    />
                  </FormFieldLabel>
                </Form.Item>
                <Form.Item key="manual-payments-payment-type" sx={{ justifyContent: "end" }}>
                  <FormFieldLabel hasErrorMessage={false} small name="type" label={t("Tenant *")}>
                    <LegacySelect
                      sx={{
                        ...(errors?.endDateTime && touched?.endDateTime
                          ? {
                              'div[class*="container"]>div': {
                                borderWidth: "1px",
                                borderColor: "warning",
                                borderStyle: "solid",
                              },
                            }
                          : {}),
                      }}
                      translation={getSelectTranslation(t)}
                      options={tenantsOptions || []}
                      disabled={tenantsOptions?.length < 2}
                      isLoading={tenantsLoading}
                      value={tenantsOptions?.find((tenant) => tenant.value === values.tenantId)}
                      onChange={(option) => {
                        setFieldValue("tenantId", option?.value);
                      }}
                    />
                  </FormFieldLabel>
                </Form.Item>
              </Grid>
              <Flex
                sx={{
                  gap: 3,
                  justifyContent: ["flex-start", "flex-end"],
                  flexWrap: "wrap",
                }}
              >
                <Form.Item key="trading-halt-isPlanned" sx={{ justifyContent: "end" }}>
                  <LegacySwitch
                    uncheckedLabel={t("UnPlanned")}
                    checked={values.isPlanned}
                    checkedLabel={t("Planned")}
                    onChange={(checked: boolean) => {
                      setFieldValue("isPlanned", checked);
                    }}
                  />
                </Form.Item>
                <Box
                  sx={{
                    width: "2px",
                    bg: "text",
                    display: ["none", ""],
                  }}
                />
                <Form.Item key="trading-halt-isApplicable" sx={{ justifyContent: "end" }}>
                  <LegacySwitch
                    uncheckedLabel={t("UnApplicable")}
                    checkedLabel={t("Applicable")}
                    checked={values.isApplicable}
                    onChange={(checked: boolean) => {
                      setFieldValue("isApplicable", checked);
                    }}
                  />
                </Form.Item>
                <Box
                  sx={{
                    width: "2px",
                    bg: "text",
                    display: ["none", ""],
                  }}
                />
                {isEdit && (
                  <Button
                    sx={{
                      width: "100px",
                    }}
                    disabled={isSubmitting}
                    onClick={onCancel}
                  >
                    {t("Cancel")}
                  </Button>
                )}

                <Button
                  sx={{
                    width: "100px",
                  }}
                  type="submit"
                  disabled={!isValid || isSubmitting || tenantsLoading}
                  onSubmit={submitForm}
                >
                  {isEdit ? t("Update") : t("Create")}
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Box>
  );
};
