import { FormikHelpers, FormikProps } from "formik";

import { BankDetails } from "@/app/components/bank-form-fields/bank-form-fields.types";
import { AddressInput, MembershipType, OnboardUserByAdminInput } from "@/app/types/generated/graphql";

export enum AddressFieldDependency {
  registeredAddress = "registeredAddress",
  businessAddress = "businessAddress",
  invoiceAddress = "invoiceAddress",
  none = "none",
}

export type AddressFieldPossibleDependencies = Exclude<keyof typeof AddressFieldDependency, "none">;
export type DependentAddressFields = "businessAddress" | "invoiceAddress" | "gstRegistrationAddress";
export type AddressFieldDependencyRelations = {
  businessAddress: AddressFieldDependency.none | AddressFieldDependency.registeredAddress;
} & {
  invoiceAddress: Exclude<keyof typeof AddressFieldDependency, "invoiceAddress">;
} & {
  gstRegistrationAddress: Exclude<keyof typeof AddressFieldDependency, "gstRegistrationAddress">;
};

export type MemberFormDetails = {
  registries: OnboardUserByAdminInput["members"]["registries"];
  invoiceEmail: string;
  invoiceContactNumber: string;
  membershipId: string;
  name: string;
  registeredAddress: AddressInput;
  businessAddress: AddressInput;
  invoiceAddress: AddressInput;
  gstin: string;
  gstRegistrationAddress: AddressInput;
  panNumber: string;
  tanNumber: string;
  addressFieldDependencyRelations: AddressFieldDependencyRelations;
};

export type ClientDetailType = {
  id?: string | null;
} & MemberFormDetails & {
    membershipType: "CLIENT";
  };

export type MemberDetailType = {
  id?: string | null;
} & MemberFormDetails & {
    membershipType: "PROPRIETOR" | "TRADER";
    email: string;
    contactNumber: string;
    settlementBank: BankDetails;
    fundBank: BankDetails;
  };
export type CreateEditAccountFormFields = MemberDetailType | ClientDetailType;

export type CreateEditAccountProps = {
  isEdit?: boolean;
  goBack: () => void;
  onSubmit: (values: CreateEditAccountFormFields, actions: FormikHelpers<CreateEditAccountFormFields>) => void;
  formikProps: Partial<FormikProps<CreateEditAccountFormFields>>;
  loading?: boolean;
  error: string;

  membershipOptions: {
    label: string;
    value: MembershipType;
  }[];
};

export type UseCreateEditAccountFn = () => CreateEditAccountProps;
