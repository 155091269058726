import { Option } from "@powerledger/ui-component-lib";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import notifications from "@/app/container/notification-handler";
import { CcAssetAttributes, useCcHoldingsForAdminLazyQuery } from "@/app/types/generated/graphql";

import { UseOverTheCounterFn } from "./otc-holdings-table-view.types";

export const useOtcHoldingsTable: UseOverTheCounterFn = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const [member, setMember] = useState<Option | undefined>(location.state?.searchKeys);

  const onMemberSelect = useCallback((value: Option) => {
    setMember(value);
  }, []);

  const [getCcHoldings, { data, loading }] = useCcHoldingsForAdminLazyQuery({
    fetchPolicy: "cache-and-network",
    onError: (error) => {
      notifications.error({
        description: t(error?.message),
      });
    },
  });

  const ccHoldings = (data?.ccHoldingsForAdmin ?? []).map((cc) => {
    const attributes = cc.attributes as CcAssetAttributes;
    return {
      ...cc,
      attributes,
      expandedData: {
        "Project Type": attributes.projectTypes,
        "Sectoral Scope": attributes.sectoralScopes,
        "Additional Co-Benefits": attributes.coBenefits,
        "Sustainable Development Goals (SDGs)": attributes.sdgGoals,
      },
    };
  });

  const fetchHoldings = useCallback(() => {
    if (member?.value) {
      getCcHoldings({
        variables: {
          where: {
            accountId: member.value,
          },
        },
      });
    }
  }, [member, getCcHoldings]);

  useEffect(() => {
    if (location.state?.searchKeys) {
      fetchHoldings();
    }
  }, [fetchHoldings, location.state?.searchKeys]);

  return {
    onMemberSelect,
    member,
    loading,
    data: ccHoldings,
    fetchHoldings,
  };
};
