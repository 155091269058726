import { Box, Flex, Header, Heading } from "@powerledger/ui-component-lib";
import { FC } from "react";
import { useTranslation } from "react-i18next";

import { LayoutViewProps } from "./layout.types";

export const LayoutView: FC<LayoutViewProps> = ({
  siderWidth,
  headerHeight,
  children,
  tooltipComponent,
  footer,
  header,
  sider,
  headerTitle,
  headerActions,
}) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ height: "100%", paddingLeft: siderWidth }}>
      {tooltipComponent}
      <Flex
        sx={{
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            marginTop: headerHeight,
            flex: 1,
            pb: 4,
            px: 4,
          }}
        >
          {(headerTitle || headerActions) && (
            <Flex
              sx={{
                justifyContent: headerTitle ? ["flex-start", "space-between"] : "flex-end",
                mb: 3,
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              {headerTitle && (
                <Box>
                  <Heading sx={{ variant: "texts.traceccDefaultHeading" }}>{t(headerTitle)}</Heading>
                </Box>
              )}
              <Flex sx={{ justifyContent: "flex-end", width: ["100%", 200] }}>{headerActions}</Flex>
            </Flex>
          )}
          {children}
        </Box>
        {footer}
      </Flex>
      <Box
        sx={{
          left: siderWidth,
          right: 0,
          top: 0,
          position: "fixed",
          backgroundColor: "background",
          zIndex: 10,
        }}
      >
        <Header>{header}</Header>
      </Box>
      <Box
        sx={{
          width: siderWidth,
          left: 0,
          top: 0,
          right: "auto",
          height: "100vh",
          position: "fixed",
        }}
      >
        {sider}
      </Box>
    </Box>
  );
};
