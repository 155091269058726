import { Box, getColor, useTheme } from "@powerledger/ui-component-lib";
import ReactTooltip from "react-tooltip";

export const GenericTooltip = () => {
  const { theme } = useTheme();

  return (
    <ReactTooltip
      backgroundColor={getColor(theme, "secondaryDark")}
      getContent={(tooltipContent) => <Box sx={{ fontSize: 1, maxWidth: 300 }}>{tooltipContent}</Box>}
    />
  );
};
