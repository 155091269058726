import { useOktaAuth } from "@okta/okta-react";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import notifications from "@/app/container/notification-handler";

import { UseForgotPasswordFn } from "./forgot-password.types";

export const useForgotPassword: UseForgotPasswordFn = () => {
  const { oktaAuth } = useOktaAuth();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const handleForgotPassword = useCallback(
    async (username) => {
      setLoading(true);
      try {
        await oktaAuth.forgotPassword({ factorType: "EMAIL", username });
        !email && setEmail(username);
      } catch (error: unknown) {
        if (error instanceof Error) {
          notifications.error({
            description: t(error?.message),
          });
        }
      } finally {
        setLoading(false);
      }
    },
    [oktaAuth, email, t],
  );

  const goBack = useCallback(() => {
    if (email) {
      setEmail("");
    } else navigate("/");
  }, [navigate, email]);

  return {
    email,
    handleForgotPassword,
    loading,
    goBack,
  };
};
