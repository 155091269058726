import {
  Box,
  CountrySelect,
  Divider,
  Flex,
  Grid,
  LegacyCheckbox,
  RegionSelect,
  Text,
} from "@powerledger/ui-component-lib";
import { isEqual } from "lodash";
import { FC, memo, useState } from "react";
import { useTranslation } from "react-i18next";

import { AddressFieldDependency } from "@/app/pages/create-edit-account/create-edit-account.types";
import { AddressInput } from "@/app/types/generated/graphql";

import { useFormikInputError } from "../../hooks/use-formik-input-error";
import { getSelectTranslation } from "../../lib/get-translations-for-components";
import { Form, FormFieldLabel, FormInput } from "../form";
import { AddressAutoComplete } from "./address-auto-complete";
import { AddressFormFieldProps } from "./address-form-section.type";

export const defaultAddress: AddressInput = {
  id: undefined,
  city: "",
  countryCode: "IN",
  line1: "",
  postalCode: "",
  state: "",
};

const AddressFormFieldsComponent: FC<AddressFormFieldProps> = ({
  setFieldValue,
  setFieldTouched,
  values,
  errors,
  touched,
  pageTouched,
  headline,
  name = "physicalAddress",
  sameAsActions = [],
  onEnterManually,
  fieldsRequired = true,
  defaultValue = defaultAddress,
}) => {
  const { getInputStyles } = useFormikInputError(errors, touched, pageTouched);

  /** Local State to handle component centric logic */
  const [sameAsState, setSameAsState] = useState(AddressFieldDependency.none);

  const { t } = useTranslation();
  const actions = sameAsActions?.length
    ? [
        {
          key: AddressFieldDependency.none,
          label: "Enter address manually",
          onChange: () => {
            setFieldValue(name, defaultValue);
            onEnterManually && onEnterManually(name);
          },
        },
        ...sameAsActions,
      ]
    : [];

  const isAddressIndependent = sameAsState === AddressFieldDependency.none;
  const displayFields = actions.length ? isAddressIndependent : true;
  return (
    <>
      <Flex sx={{ my: 4 }}>
        <Text
          sx={{
            ...(headline?.toUpperCase ? { textTransform: "uppercase" } : {}),
            fontSize: 0,
            color: "textDarker",
            mr: 4,
          }}
        >
          {t(headline?.text || "Address")}
        </Text>
        <Divider sx={{ flex: 1, color: "textDarkest" }} />
      </Flex>
      <Flex
        sx={{
          mb: 3,
          gap: 3,
          flexWrap: "wrap",
        }}
      >
        {actions?.map((action) => {
          return (
            <LegacyCheckbox
              key={action.key}
              rounded
              type="radio"
              role="radiogroup"
              sx={{
                "&>svg": {
                  mr: 2,
                },
                color: action.key === sameAsState ? "primary" : "text",
                ...(action.disabled ? { cursor: "not-allowed", opacity: 0.5 } : {}),
              }}
              checked={action.key === sameAsState}
              label={t(action.label)}
              disabled={action.disabled}
              onChange={(e) => {
                setSameAsState(e.target.checked ? action.key : AddressFieldDependency.none);
                e.target.checked && action.onChange(action.key);
              }}
            />
          );
        })}
      </Flex>
      {displayFields && (
        <>
          <Grid gap={3} columns={[1, 2]}>
            <Form.Item sx={{ flex: 1 }}>
              <Flex
                sx={{
                  alignItems: "flex-start",
                  justifyContent: "flex-end",
                }}
              >
                <FormFieldLabel
                  hasErrorMessage={false}
                  sx={{
                    ...(getInputStyles ? getInputStyles(`${name}.countryCode`, "select") : {}),
                    flex: 1.5,
                  }}
                  small
                  name={`${name}.countryCode`}
                  label={t(`Country ${fieldsRequired ? "*" : ""}`)}
                />
              </Flex>
              <CountrySelect
                translation={getSelectTranslation(t)}
                inputValue={values?.countryCode || ""}
                disabled={!isAddressIndependent}
                isClearable
                onChange={(option) => {
                  setFieldValue(`${name}.countryCode`, option?.value);
                  if (name === "gstRegistrationAddress" && option?.value === undefined) {
                    setFieldValue(`${name}`, null);
                  }
                }}
              />
            </Form.Item>
            <Box sx={{ flex: 1 }}>
              <AddressAutoComplete
                country={values?.countryCode}
                receiveAddress={(address) => {
                  setFieldValue(`${name}.state`, address.region_code);
                  setFieldValue(`${name}.city`, address.address_level2);
                  setFieldValue(`${name}.line2`, address.address_line2);
                  setFieldValue(`${name}.postalCode`, address.postcode);
                }}
              >
                <Form.Item
                  onBlur={() => {
                    /** Required as address autofills adds extra name like address-search in the input field hence making touched field for [physicalAddress.line1 address-search] to be true */
                    setFieldTouched(`${name}.line1`, true, true);
                  }}
                >
                  <FormFieldLabel
                    hasErrorMessage={false}
                    small
                    name={`${name}.line1`}
                    label={t(`Street Address ${fieldsRequired ? "*" : ""}`)}
                  >
                    <FormInput
                      debounce
                      hasErrorIndicator={false}
                      name={`${name}.line1`}
                      disabled={!values?.countryCode || !isAddressIndependent}
                      sx={{
                        "&::placeholder": {
                          color: "textDarkerLighter",
                        },
                      }}
                      placeholder={t("Start Typing Your Address...")}
                    />
                  </FormFieldLabel>
                </Form.Item>
              </AddressAutoComplete>
            </Box>
          </Grid>
          <Grid gap={3} columns={[1, 2, 3]} sx={{ mt: 3, alignItems: "end" }}>
            <Form.Item sx={{ justifyContent: "end" }}>
              <FormFieldLabel
                sx={getInputStyles && getInputStyles("state", "select")}
                hasErrorMessage={false}
                small
                name={`${name}.state`}
                label={t(`State ${fieldsRequired ? "*" : ""}`)}
              >
                <RegionSelect
                  translation={getSelectTranslation(t)}
                  countryValue={values?.countryCode || ""}
                  inputValue={values?.state || ""}
                  disabled={!isAddressIndependent}
                  isClearable
                  onBlur={() => {
                    setFieldTouched(`${name}.state`, true, true);
                  }}
                  onChange={(option) => setFieldValue(`${name}.state`, option?.value)}
                />
              </FormFieldLabel>
            </Form.Item>
            <Form.Item sx={{ justifyContent: "end" }}>
              <FormFieldLabel
                hasErrorMessage={false}
                small
                name={`${name}.city`}
                label={t(`City ${fieldsRequired ? "*" : ""}`)}
              >
                <FormInput
                  debounce
                  hasErrorIndicator={false}
                  name={`${name}.city`}
                  disabled={!values?.countryCode || !isAddressIndependent}
                />
              </FormFieldLabel>
            </Form.Item>

            <Form.Item sx={{ justifyContent: "end" }}>
              <FormFieldLabel
                hasErrorMessage={errors?.postalCode !== "Required"}
                small
                name={`${name}.postalCode`}
                label={t(`Zip Code/Postal Code ${fieldsRequired ? "*" : ""}`)}
              >
                <FormInput
                  debounce
                  hasErrorIndicator={false}
                  name={`${name}.postalCode`}
                  disabled={!values?.countryCode || !isAddressIndependent}
                />
              </FormFieldLabel>
            </Form.Item>
          </Grid>
        </>
      )}
    </>
  );
};

export const AddressFormFields = memo(AddressFormFieldsComponent, (prevProps, nextProps) =>
  isEqual(prevProps, nextProps),
);
