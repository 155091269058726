import OktaAuth, { toRelativeUrl } from "@okta/okta-auth-js";
import { Security, useOktaAuth } from "@okta/okta-react";
import { useCallback } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";

import { LoadingOverlayIndicator } from "../components";
import { Layout } from "../components/layout";
import { useOktaRoles } from "../hooks/use-okta-roles";
import { grantAccess } from "../lib/role-based-access-control";
import { Guards } from "./navigation.types";
import { routes } from "./routes";

const PrivateRoute = ({ children, guards }: { guards?: Guards; children: JSX.Element }) => {
  const { authState } = useOktaAuth();

  const { userRoles } = useOktaRoles();

  if (!authState || !userRoles) {
    return <LoadingOverlayIndicator />;
  }

  if (!grantAccess(guards?.roles || [], userRoles)) {
    return <Navigate to="/" />;
  }

  if (!authState?.isAuthenticated) {
    return <Navigate to="/" />;
  }
  return (
    <>
      <Layout userRoles={userRoles} toggles={{}}>
        {children}
      </Layout>
    </>
  );
};

export const AppRouter: React.FC<{ oktaAuth: OktaAuth }> = ({ oktaAuth }) => {
  const navigate = useNavigate();

  const restoreOriginalUri = useCallback(
    async (_, originalUri: string | null) => {
      navigate(toRelativeUrl(originalUri || "/", window.location.origin), {
        replace: true,
      });
    },
    [navigate],
  );

  const onAuthRequired = useCallback(() => {
    navigate("/", {
      replace: true,
    });
  }, [navigate]);

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri} onAuthRequired={onAuthRequired}>
      <Routes>
        {/* 
          One level children only considered, 
        */}
        {routes.map(({ path, element, children, secured, guards }) => (
          <Route
            key={path}
            path={path}
            element={secured ? <PrivateRoute guards={guards}>{element}</PrivateRoute> : element}
          >
            {children?.map(({ path, element }) => (
              <Route key={path} path={path} element={element} />
            ))}
          </Route>
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Security>
  );
};
